import React from 'react'
import PageComponent from '../../../../component/common/Page-Component'
import { useParams } from 'react-router-dom';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import moment from 'moment';
import { userValetExportData } from '../../../../util/exportData';

const VisitLedger = () => {
  const { id } = useParams();
  return (
    <>
      <PageComponent
        tableTitle="Visit Ledger List"
        tableHeaders="CLUB_VISIT_LEDGER"
        getAPI={CONSTANTS.API.getClubVisitLedger}
        extraParams={`&ClubId=${id}`}
        extraResData="visitLedgers"
        getData={(res) => {
          // console.log(res);
          return res?.map(data => {
            return {
              ...data,
              no: `V${data?.id.toString().padStart(7, "0")}`,
              name: data?.Booking?.Event?.name,
              userName: data?.User?.name,
              time: moment.utc(data?.createdAt).local().format("hh:mm A"),
              date: moment.utc(data?.createdAt).local().format('DD MMM, YYYY'),



            }
          })
        }}
        exportCSV
        exportData={userValetExportData}
      />
    </>
  );
}

export default VisitLedger