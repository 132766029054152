import React from "react";
import { Card, Col, Row } from "antd";
import { Pie, measureTextWidth } from "@ant-design/charts";

const ClubProfileDetails = ({
  totalVisits,
  followers,
  events,
  acceptedEvents,
  pendingEvents,
  rejectedEvents,

}) => {
  const data = [
    {
      type: 'Accept',
      value: acceptedEvents
    },
    {
      type: 'Pending',
      value: pendingEvents
    },
    {
      type: 'Reject',
      value: rejectedEvents
    },
  ]
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  }

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} ¥`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    statistic: {
      title: {
        offsetY: -4,

        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : 'Total Request';
          return renderStatistic(110, text, {
            fontSize: 20,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '18px',
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum ? `${datum.value}` : `${data.reduce((r, d) => r + d.value, 0)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };
  return (
    <>
      <Row gutter={[16, 16]} className="select-text">
        <Col span={14} xs={24} sm={24} md={14} lg={14} xl={14}>
          <Card size="small" className="py-7 px-4">
            <div>
              <p className="font-semibold text-lg text-slate-400">RSVP Acceptance</p>
              <div>
                <Pie {...config} height={250} />
              </div>
            </div>
          </Card>
        </Col>
        <Col span={10}>
          <Row gutter={[16, 8]}>
            <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card size="small" className="py-0 px-4">
                <div>
                  <p className="font-semibold text-lg text-slate-400">Followers</p>
                  <p className=" text-xl font-medium">{followers || 0}</p>
                </div>
              </Card>
            </Col>
            <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card size="small" className="py-0 px-4">
                <div>
                  <p className="font-semibold  text-lg text-slate-400">
                    Total Events
                  </p>
                  <p className=" text-xl font-medium">{events || 0}</p>
                </div>
              </Card>
            </Col>
            <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card size="small" className="py-0 px-4">
                <div>
                  <p className="font-semibold text-lg text-slate-400">Total Visits</p>
                  <p className=" text-xl font-medium">{totalVisits || 0}</p>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>


      </Row>
    </>
  );
};

export default ClubProfileDetails;

ClubProfileDetails.defaultProps = {
  totalVisits: '',
  followers: '',
  events: '',
}
