import React from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { userExportData } from "../../../util/exportData";

// const filterList = [
//   {
//     id: 1,
//     label: "Private Account",
//     value: "private account",
//   },
//   {
//     id: 2,
//     label: "Age Range",
//     value: "age",
//   },
//   {
//     id: 3,
//     label: "Gender",
//     value: "gender",
//   },
// ];

const filterList = [

    {
        title: "Select All",
        value: "",
    },
    {
        title: "Private Account",
        value: "privateAcc=1",
    },
    {
        title: "Public Account",
        value: "privateAcc=0",
    },

    {
        title: "Age Range",
        value: "a",
        selectable: false,

        children: [
            {
                title: "01-18 Years",
                value: "minAge=1&maxAge=18",
            },
            {
                title: "18-24 Years",
                value: "minAge=18&maxAge=24",
            },
            {
                title: "24-30 Year",
                value: "minAge=24&maxAge=30",
            },
            {
                title: "30+ Year",
                value: "minAge=30",
            },
        ],
    },
    {
        title: "Gender",
        value: "g",
        selectable: false,

        children: [
            {
                title: "Male",
                value: "gender=Male",
            },
            {
                title: "Female",
                value: "gender=Female",
            },
            {
                title: "Other",
                value: "gender=Other",
            },

        ],
    },

];

const Users = () => {
    const navigate = useNavigate();

    const userDetail = (id) => {
        navigate(`/app/users/${id}`);
    };
    return (
        <>
            <PageComponent
                tableHeaders="USERS"
                tableTitle="Users List"
                getAPI={CONSTANTS.API.getUsers}
                extraResData="users"
                getData={(res) => {
                    // console.log(res);
                    return res?.map((data) => {
                        return {
                            ...data,
                            no: `U${data?.id.toString().padStart(7, "0")}`,
                            dob: moment(data?.dob).format("DD MM, YYYY"),
                            privateAccD: data?.privateAcc ? 'Yes' : 'No',
                            isBlockedD: data?.isBlocked ? 'Yes' : 'No'
                        };
                    });
                }}

                viewData
                viewFunction={userDetail}
                blockData
                editAPI={CONSTANTS.API.editUser}
                deleteData
                deleteAPI={CONSTANTS.API.deleteUsers}
                checkboxData
                filterparmas
                filterList={filterList}
                searchfilter
                isSearch
                searchAPI="/users/search"
                exportData={userExportData}
                exportCSV
            />
        </>
    );
};

export default Users;
