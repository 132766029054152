import React from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useNavigate } from "react-router-dom";
import { clubExportData } from "../../../util/exportData";

const Clubs = () => {
    const navigate = useNavigate();

    const clubDetail = (id) => {
        navigate(`/app/clubs/${id}`);
    };




    return (
        <div>
            <PageComponent
                tableHeaders="CLUBS"
                tableTitle="Club List"
                getAPI={CONSTANTS.API.getClubs}
                extraResData="clubs"
                getData={(res) => {
                    return res?.map((data) => {

                        return {
                            ...data,
                            no: `C${data?.id.toString().padStart(7, "0")}`,
                        };
                    });
                }}
                viewData
                viewFunction={clubDetail}
                dataBaseSearch
                searchAPI="/clubs/search"
                isSearch
                searchfilter
                datefilter
                exportData={clubExportData}
                exportCSV
                deleteData
                deleteAPI={CONSTANTS.API.deleteClubs}
            />
        </div>
    );
};

export default Clubs;
