import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "antd";
import InfiniteScroll from 'react-infinite-scroll-component';
import user from "../../../../asset/image/dummy-avatar.jpg";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import useHttp from "../../../../hooks/use-http";
import RecieverChat from "./reciever-chat";
import SenderChat from "./sender-chat";
import moment from "moment";



const Chat = ({
  name = "no data",
  role = "no data",
}) => {
  const [chatsRecords, setChatsRecords] = useState([]);
  const [recipentData, setRecipentData] = useState({});
  const [totalRecords, setTotalRecords] = useState(0)
  const [page, setPage] = useState(1);
  const { id, userId } = useParams();
  // const use = useParams()
  const api = useHttp();


  const fetchMore = () => {
    setPage(page + 1);
  }


  useEffect(() => {
    const GET_CHATS_API = apiGenerator(CONSTANTS.API.getChats, {}, `?ChatRecordId=${id}&page=${page}&limit=8`);


    api.sendRequest(GET_CHATS_API, (res) => {

      // console.log(res?.data);
      setTotalRecords(res?.totalData);
      let sortedMsgs = res?.data?.messages?.map(ele => {
        // console.log(ele?.sender?.split("_")[1] === id.toString());
        if (ele?.sender?.split("_")[1] === userId.toString()) {
          return {
            ...ele,
            sendByMe: true,
            time: `${moment.utc(ele?.createdAt).local().format('DD/MM/YYYY')} ${moment.utc(ele?.createdAt).local().format('hh:mm A')}`
          }
        }
        else {
          return {
            ...ele,
            sendByMe: false,
            time: `${moment.utc(ele?.createdAt).local().format('DD/MM/YYYY')} ${moment.utc(ele?.createdAt).local().format('hh:mm A')}`
          }
        }
      });
      sortedMsgs = sortedMsgs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      // console.log(sortedMsgs);
      setChatsRecords(prev => {
        return [...prev, sortedMsgs].flat()
      });
    })



  }, [page])


  useEffect(() => {
    const RECIPENT_DETAILS_API = { ...CONSTANTS.API.getUserChatHistoryList }
    RECIPENT_DETAILS_API.endpoint = `${RECIPENT_DETAILS_API.endpoint}/${userId}`
    api.sendRequest(RECIPENT_DETAILS_API, (res) => {

      const data = res?.data?.chatRecords?.filter(ele => ele?.id === +id)
      if (data[0]?.UserChats?.length !== 0) {
        setRecipentData({
          name: data[0]?.UserChats[0]?.User1?.name || data[0]?.UserChats[0]?.User2?.name,
          image: data[0]?.UserChats[0]?.User1?.profilePic || data[0]?.UserChats[0]?.User2?.profilePic,
          role: 'User'
        })
      }
      if (data[0]?.UserManagerChats?.length !== 0) {
        setRecipentData({
          name: data[0]?.UserManagerChats[0]?.Manager?.name,
          image: data[0]?.UserManagerChats[0]?.Manager?.profilePic,
          role: 'Manager'
        })
      }
      if (data[0]?.GroupChat) {
        setRecipentData({
          name: data[0]?.GroupChat?.name,
          image: data[0]?.GroupChat?.image,
          role: 'Group'
        })
      }
    })
  }, [])

  return (
    <div className="mt-5 mx-3">
      <div>

        <Row gutter={[24, 16]}>
          <Col span={10}>
            <Card >
              <section>
                <div className="text-center">
                  <p className="font-medium text-xl">Profile</p>
                </div>
                <div className="flex justify-center">
                  <Image
                    src={recipentData?.image || user}
                    preview
                    width={300}
                    height={300}
                    className="rounded-2xl object-cover"
                  />
                </div>
                {recipentData?.role === 'Group' ? <div className="m-5">
                  <div className="flex ml-28">
                    <p className="text-base  text-slate-400 ">Group Name</p>
                    <p className="text-base font-semibold ">&nbsp;: {recipentData?.name || name} </p>
                  </div>

                </div> : <div className="m-5">
                  <div className="flex ml-28">
                    <p className="text-base  text-slate-400 ">Name</p>
                    <p className="text-base font-semibold ">&nbsp;: {recipentData?.name || name} </p>
                  </div>
                  <div className="flex ml-28">
                    <p className="text-base text-slate-400 ">Role</p>
                    <p className="text-base font-semibold ">&nbsp;: {recipentData?.role || role} </p>
                  </div>
                </div>}
              </section>
            </Card>
          </Col>
          <Col span={14}>
            <Card  >
              <p className="font-medium mt-5 text-center text-xl">Chat</p>
              <div
                id="scrollableDiv"
                style={{
                  height: '80vh',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
              >
                <div className="mr-5">

                  {/*Put the scroll bar always on the bottom*/}
                  <InfiniteScroll
                    dataLength={chatsRecords?.length}
                    next={fetchMore}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                    inverse={true} //
                    hasMore={chatsRecords?.length !== totalRecords}
                    loader={<h4 className="text-center font-bold">Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                  >
                    {chatsRecords?.map(data => <Row key={data?.id} className="">
                      <Col span={24} className="my-1 " >
                        {data?.sendByMe ? <SenderChat {...data} /> : <RecieverChat {...data} />}
                      </Col>

                    </Row>)}
                  </InfiniteScroll>
                </div>
              </div>






            </Card>

          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Chat;
