import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Popconfirm, Row, Spin } from "antd";
import { useParams } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import imageSrc from "../../../asset/image/image 2.png";
import panImage from "../../../asset/image/no image.png";
import dayjs from "dayjs";

const RequestAboutInfo = ({
  image,
  ownerName,
  clubUserName,
  name,
  bio,
  dob,
  mobile,
  email,
  website,
  type,
  address,
  pincode,
  landMark,
  GST,
  PAN,
  bankHolder,
  branch,
  IFSC,
  panFile,
  aadhaarFile,
  fssaiCertificate,
}) => {
  const [info, SetInfo] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const { id } = useParams();
  const api = useHttp();

  console.log(info);
  // Accept Request
  const acceptRequest = () => {
    const payload = {
      accept: true,
      ClubId: id,
    };
    // console.log(payload);
    api.sendRequest(
      CONSTANTS.API.acceptOrRejectRequest,
      () => {
        setRefresh((prev) => !prev);
        setIsDisable((prev) => !prev);
      },
      payload,
      "Request Accepted"
    );
  };

  // Reject Request
  const rejectRequest = () => {
    const payload = {
      accept: false,
      ClubId: id,
    };
    // console.log(payload);
    api.sendRequest(
      CONSTANTS.API.acceptOrRejectRequest,
      () => {
        setRefresh((prev) => !prev);
        setIsDisable((prev) => !prev);
      },
      payload,
      "Request Rejected"
    );
  };

  useEffect(() => {
    const INFO_API = apiGenerator(CONSTANTS.API.getOneClubRequest, {
      requestId: id,
    });
    api.sendRequest(INFO_API, (res) => {
      // console.log(res?.data?.club);
      SetInfo({
        DOB:
          dayjs(res?.data?.club[0]?.ownerDOB).format("MMM DD, YYYY") ||
          "",
        mobile: res?.data?.club[0]?.mobile || "",
        name: res?.data?.club[0]?.name || " ",
        clubUserName: res?.data?.club[0]?.clubUserName || " ",
        bio: res?.data?.club[0]?.bio,
        ownerName: res?.data?.club[0]?.ownerName || " ",
        email: res?.data?.club[0]?.email || "",
        website: res?.data?.club[0]?.website || "",
        type: res?.data?.club[0]?.type || "",
        pinCode: res?.data?.club[0]?.pinCode || "",
        address: res?.data?.club[0]?.address || "",
        landMark: res?.data?.club[0]?.landMark || "",
        GST: res?.data?.club[0]?.GST || "",
        PAN: res?.data?.club[0]?.PAN || "",
        bancAccHolderName: res?.data?.club[0]?.bancAccHolderName || "",
        bancAccNo: res?.data?.club[0]?.bancAccNo || "",
        bancBranch: res?.data?.club[0]?.bancBranch || "",
        IFSC: res?.data?.club[0]?.IFSC || "",
        image: res?.data?.club[0]?.image,
        aadhaarFile: res?.data?.club[0]?.aadhaarFile,
        panFile: res?.data?.club[0]?.panFile,
        fssaiCertificate: res?.data?.club[0]?.fssaiCertificate,
        lat: res?.data?.club[0]?.lat,
        lng: res?.data?.club[0]?.lng,
      });
    });
  }, [refresh]);

  return (
    <>
      {api.isLoading ? (
        <Spin className="mt-16" tip="Loading" size="large">
          <div className="content" />
        </Spin>
      ) : (
        <div className="select-text">
          <div className="my-5">
            <Row gutter={[16, 16]}>
              <Col span={14} xs={24} sm={24} md={24} lg={7} xl={7}>
                <Card className="h-40">
                  <Row className=" ml-5 mt-3">
                    <Col span={6}>
                      <Image
                        width={70}
                        height={70}
                        className="rounded-full"
                        preview
                        src={info?.image || image}
                        alt="Images is Not Availabel"
                      />
                    </Col>
                    <Col span={18}>
                      <p className="font-bold mt-4 text-2xl">
                        {info?.name || name}
                        {/* Indigo XP */}
                      </p>
                      <p className="font-bold text-sm -mt-6 text-slate-400">
                        {info?.clubUserName || clubUserName}
                        {/* Indigo XP */}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={16}>
                <div className="float-right mt-20">
                  <Popconfirm
                    placement="top"
                    title={"Are you sure to Reject this request?"}
                    // description={description}
                    onConfirm={rejectRequest}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className="ml-2 text-white mr-2"
                      style={{ backgroundColor: "#dc2626" }}
                      disabled={isDisable}
                    >
                      <CloseCircleTwoTone twoToneColor="#dc2626" />
                      Reject
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    placement="top"
                    title={"Are you sure to Accept this request?"}
                    // description={description}
                    onConfirm={acceptRequest}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{ backgroundColor: "#52c41a" }}
                      className="text-white"
                      disabled={isDisable}
                    >
                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                      Approve
                    </Button>
                  </Popconfirm>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Card>
              <Row>
                <Col span={16}>
                  <p className="font-bold text-2xl">About Info</p>
                  <div className="mt-3 flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Owner Name
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.ownerName || ownerName}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Bio
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.bio || bio}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Date of birth
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.DOB || dob}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Mobile Number
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.mobile || mobile}
                    </p>
                  </div>

                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Email
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.email || email}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Website
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.website || website}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Type
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.type || type}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Address
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.address || address}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Pin Code
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.pinCode || pincode}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Landmark
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.landMark || landMark}
                    </p>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      GST Number
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.GST || GST}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Pan Number
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.PAN || PAN}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Bank account holder Name
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.bancAccHolderName || bankHolder}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      Branch bank Account
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.bancBranch || branch}
                    </p>
                  </div>
                  <div className="flex ml-5">
                    <p className="font-semibold text-slate-400 text-base">
                      IFSC Code
                    </p>
                    <p className="font-bold text-base">
                      &nbsp;: {info?.IFSC || IFSC}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col span={8}>
                  <h2 className="font-bold">Pan Card</h2>
                  <Image
                    src={info?.panFile || panFile}
                    width={300}
                    height={200}
                    className="rounded-2xl"
                  />
                </Col>
                <Col span={8}>
                  <h2 className="font-bold">Aadhar Card</h2>
                  <Image
                    src={info?.aadhaarFile || aadhaarFile}
                    width={300}
                    height={200}
                    className="rounded-2xl"
                  />
                </Col>
                <Col span={5}>
                  <h2 className="font-bold">Fssai Proof</h2>
                  <Image
                    src={info?.fssaiCertificate || fssaiCertificate}
                    width={300}
                    height={200}
                    className="rounded-2xl"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <h2 className="font-bold">Location</h2>
                  <iframe
                    width={300}
                    height={200}
                    src={`https://maps.google.com/?q=${info?.lat},${info?.lng}&ie=UTF8&iwloc=&output=embed`}
                  ></iframe>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestAboutInfo;

RequestAboutInfo.defaultProps = {
  image: imageSrc,
  ownerName: "",
  clubUserName: "",
  name: "",
  bio: "",
  dob: "",
  mobile: "",
  email: "",
  website: "",
  type: "",
  address: "",
  pincode: "",
  landMark: "",
  GST: "",
  PAN: "",
  bankHolder: "",
  branch: "",
  IFSC: "",
  panFile: panImage,
  aadhaarFile: panImage,
  fssaiCertificate: panImage,
};