import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Select,
  Table,
  TreeSelect,
} from "antd";
import CONSTANTS from "../../util/constant/CONSTANTS";
import { CSVLink } from "react-csv";
import Search from "antd/es/transfer/search";
import Heading from "./Heading";
import { Option } from "antd/es/mentions";
import useHttp from "../../hooks/use-http";
import CustomSearchBar from "./Custom-search";

const CustomTable = ({
  name,
  title,
  dataSource,
  isLoading,
  Other = {},
  extraclass,
  filterparmas = false,
  filterList = [],
  setSelectedOption,
  selectedOption,
  exportData,
  csvList = [],
  setChanges = () => { },
}) => {
  const [csvData, setCsvData] = useState([])

  // console.log(pagination);

  // Set Number of Pages


  const filterHandler = (value) => {
    // console.log(value);
    setSelectedOption(value);
  };


  const isDataEmpty = csvList?.length <= 0;
  let CSVData = [];

  if (exportData?.length) {
    if (!isDataEmpty) {
      CSVData[0] = exportData?.map((el) => el[1]);
      csvData?.map((item, index) => {
        CSVData[index + 1] = exportData
          ?.map((el) => el[0])
          ?.map((val) => {
            if (item != null && val in item) return item[val];
            return "";
          });
        return 0;
      });
    }
  } else {
    CSVData[0] = CONSTANTS.TABLE[name]?.map((el) => el.title);
    csvData?.map((item, index) => {
      CSVData[index + 1] = CONSTANTS.TABLE[name]?.map(
        (el) => item[el.dataIndex]
      );
      return 0;
    });
  };
  useEffect(() => {

    if (csvList?.length !== 0) {
      setCsvData(csvList)
    }
  }, [csvList]);

  return (
    <Row className="my-5">
      <Col
        span={23}
        style={{
          // marginBlock: "15px",
        }}
        className="container-body"
      >
        <Row className="mb-5">
          <Col span={16} lg={16} xl={16} xxl={17}>
            <Heading>{title}</Heading>
          </Col>
          {filterparmas && (
            <Col span={4} lg={4} xl={4} xxl={3}>
              <div className="">
                <TreeSelect
                  style={{
                    width: "100%",
                  }}
                  dropdownStyle={{
                    overflow: "auto",
                  }}
                  allowClear
                  // defaultValue={defaultFilterOption}
                  value={selectedOption}
                  placeholder="Please an Option"
                  treeDefaultExpandAll
                  onChange={filterHandler}
                  treeData={filterList}
                />
              </div>
            </Col>
          )}
          <Col
            span={filterparmas ? 4 : 8}
            lg={filterparmas ? 4 : 8}
            xl={filterparmas ? 4 : 8}
            xxl={filterparmas ? 4 : 7}
          >
            <div className="mr-5">
              <CSVLink filename={`${title} Table.csv`} data={CSVData}>
                <Button
                  className="float-right"
                  type="primary"
                  ghost
                  onClick={() => { }}
                // {...props.ButtonDefault}
                >
                  Export CSV
                </Button>
              </CSVLink>
            </div>
          </Col>
        </Row>

        <Table
          rowClassName={`rows-custom ${extraclass}`}
          pagination={false}
          loading={isLoading}
          // rowSelection={{
          //   type: "checkbox",
          //   ...rowSelection,
          // }}
          onChange={(_e, _ee, sort) => {
            const NewSort = {
              sortBy: sort?.field,
              sortOrder:
                sort?.order === "ascend"
                  ? "ASC"
                  : sort?.order === "descend"
                    ? "DESC"
                    : null,
            };
            let NewChanges = {};
            if (NewSort.sortOrder && NewSort?.sortBy !== "no") {
              NewChanges = { ...NewSort };
            }
            if (NewSort.sortOrder && NewSort?.sortBy === "no") {
              NewChanges = { ...NewSort, sortBy: 'id' };
            }
            setChanges(NewChanges);
          }}
          className="select-text"
          scroll={{ x: 800, y: 1300 }}
          {...Other}
          dataSource={dataSource}
          columns={CONSTANTS.TABLE[name]}
        />
      </Col>

    </Row>
  );
};
CustomTable.defaultProps = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};
export default CustomTable;
