import React from 'react'
import PageComponent from '../../../../../component/common/Page-Component';
import { Link, useParams } from 'react-router-dom';
import CONSTANTS from '../../../../../util/constant/CONSTANTS';
import { clubBannerExportData } from '../../../../../util/exportData';

const Banners = () => {
  const { id } = useParams();
  return (
    <>
      <PageComponent
        tableTitle="Banner List"
        tableHeaders="CLUB_FLOATING_ICON_BANNER"
        getAPI={CONSTANTS.API.getClubBanner}
        extraParams={`&ClubId=${id}`}
        extraResData="banners"
        getData={(res) => {
          // console.log(res);
          return res?.map(data => {
            return {
              ...data,
              no: `B${data?.id.toString().padStart(7, "0")}`,
              dimensions: `${data?.dimensions} px`,
              linkD: <><a href={data?.link} target='_blank'>{data?.link}</a></>


            }
          })
        }}
        exportData={clubBannerExportData}
        exportCSV
      />
    </>
  );
}

export default Banners