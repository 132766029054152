import React from 'react'
import PageComponent from '../../../../../component/common/Page-Component';
import { useParams } from 'react-router-dom';
import CONSTANTS from '../../../../../util/constant/CONSTANTS';
import moment from 'moment';
import { clubCarValetExportData } from '../../../../../util/exportData';

const CarValet = () => {
  const { id } = useParams();
  return (
    <>
      <PageComponent
        tableTitle="Car Valet List"
        tableHeaders="CLUB_FLOATING_ICON_CAR_VALET"
        getAPI={CONSTANTS.API.getClubValetParking}
        extraParams={`&ClubId=${id}`}
        extraResData="parkingRequests"
        getData={(res) => {
          // console.log(res);
          return res?.map(data => {
            return {
              ...data,
              no: `V${data?.id.toString().padStart(7, "0")}`,
              userName: data?.User?.name,
              keyNumber: data?.CarQrId,
              dateTime: `${moment.utc(data?.createdAt).local().format("DD MMM")}, ${moment.utc(data?.createdAt).local().format("hh:mm A")}`,
            }
          })
        }}
        exportData={clubCarValetExportData}
        exportCSV
      />
    </>
  );
}

export default CarValet