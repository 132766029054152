import React from 'react'
import CONSTANTS from '../../../util/constant/CONSTANTS';
import { userExportData, waitUserExportData } from '../../../util/exportData';
import PageComponent from '../../../component/common/Page-Component';
import moment from 'moment';

const WaitList = () => {
    return (
        <>
            <>
                <PageComponent
                    tableHeaders="WAIT_USERS"
                    tableTitle="Waiting Users List"
                    getAPI={CONSTANTS.API.getWaitListUsers}
                    extraResData="WaitLists"
                    getData={(res) => {
                        // console.log(res);
                        return res?.map((data) => {
                            return {
                                ...data,
                                no: `UW${data?.id.toString().padStart(7, "0")}`,
                                date: `${moment.utc(data?.createdAt).local().format('DD MMM, YYYY')} ${moment.utc(data?.createdAt).local().format("hh:mm A")}`,
                            };
                        });
                    }}

                    exportCSV
                    exportData={waitUserExportData}
                />
            </>
        </>
    )
}

export default WaitList