import React from 'react'
import CONSTANTS from '../../../util/constant/CONSTANTS'
import PageComponent from '../../../component/common/Page-Component'
import { foodCategoryExportData } from '../../../util/exportData'

const FodoCategory = () => {
    return (
        <>
            <PageComponent
                tableTitle="Food Category List"
                tableHeaders="FOOD_CATEGORY"
                getAPI={CONSTANTS.API.getFoodCategory}
                extraResData="foodCategories"
                getData={(res) => {
                    // console.log(res);
                    return res?.map(data => {
                        return {
                            ...data,
                            no: `FC${data?.id.toString().padStart(7, "0")}`,


                        }
                    })
                }}
                addData
                addModalTitle='Add Food Category'
                modalButton='Add Food Category'
                modalFields='FOOD_CATEGORY_MODAL'
                addAPI={CONSTANTS.API.addFoodCategory}
                editAPI={CONSTANTS.API.editFoodCategory}
                editData
                deleteData
                deleteAPI={CONSTANTS.API.deleteFoodCategory}
                exportData={foodCategoryExportData}
                exportCSV


            />

        </>
    )
}

export default FodoCategory