import React, { useState } from 'react'
import { Button, Card, Col, Image, Row, Switch } from 'antd';
import imageSrc from "../../../asset/image/image 2.png";
import ModalFormCreator from '../../../component/common/ModalFormCreator';
import useHttp from '../../../hooks/use-http';
import { useParams } from 'react-router-dom';
import { apiGenerator } from '../../../util/functions';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import { CSVLink } from 'react-csv';
import { singleClubExportData } from '../../../util/exportData';

const ClubProfile = (data) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const api = useHttp();
  const { id } = useParams()
  const dataId = id

  let { image, name, clubUserName, bio, fcm } = data

  let dataSource = [];
  let CSVData = []
  const exportData = singleClubExportData;

  dataSource?.push(data)

  if (exportData?.length) {
    if (!(dataSource?.length <= 0)) {
      CSVData[0] = exportData?.map((el) => el[1]);
      dataSource?.map((item, index) => {
        CSVData[index + 1] = exportData
          ?.map((el) => el[0])
          ?.map((val) => {
            if (item != null && val in item) return item[val];
            return "";
          });
        return 0;
      });
    }
  } else {
    CSVData[0] = CONSTANTS.TABLE[name]?.map((el) => el.title);
    dataSource?.map((item, index) => {
      CSVData[index + 1] = CONSTANTS.TABLE[name]?.map(
        (el) => item[el.dataIndex]
      );
      return 0;
    });
  };


  const notificationHandler = (value) => {
    const payload = {
      ...value,
      fcm,
    }
    // console.log(payload);
    const SENT_NOTIFICATION_API = { ...CONSTANTS.API.sentNotification }
    api.sendRequest(SENT_NOTIFICATION_API, () => {
      setIsModalOpen(prev => !prev)
    }, payload, 'Sent Notification Sucessfully!!!')
  };

  const blockClub = (isBlocked) => {
    const payload = {
      isBlocked: isBlocked,
    };


    const BLOCK_API_CALL = apiGenerator(CONSTANTS.API.editClubs, {
      dataId,
    });
    api.sendRequest(
      BLOCK_API_CALL,
      () => {
        // setRefresh((prev) => !prev);
      },
      payload
    );

  };
  return (
    <div className='select-text'>
      <Row gutter={[16, 16]}>
        <Col span={14} xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card className="py-9">
            <div>
              <Row className="mt-5">
                <Col span={7}>
                  <Image
                    width={80}
                    height={80}
                    className="rounded-full"
                    preview
                    src={image}
                    alt="Images is Not Availabel"
                  />
                </Col>
                <Col span={17}>
                  <p className="font-bold mt-4 lg:text-xl ml-5">{name}</p>
                  <p className="font-bold text-sm  ml-5 -mt-6 text-slate-400">
                    {clubUserName}
                  </p>
                </Col>

              </Row>
              <ModalFormCreator
                open={isModalOpen}
                name="Personal Notification"
                menu="NOTIFICATION_MODAL"
                onCreate={notificationHandler}
                onCancel={() => {
                  setIsModalOpen((prev) => !prev);
                }}
              />
              <div className="mt-7">
                <Row className="">
                  <Col span={24}>
                    <div className="">
                      <p className="text-lg text-slate-400">
                        Bio :&nbsp;
                        <span className="text-black font-medium">
                          {bio}
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="">
                      <p className="text-lg text-slate-400 inline-block">
                        Block
                      </p>
                      <Switch
                        className="ml-2 -mt-1"
                        onChange={blockClub}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <CSVLink filename={`Club_${id} About Info.csv`} data={CSVData}>
                      <Button
                        className=""
                        type="primary"
                        ghost
                        onClick={() => { }}
                      // {...props.ButtonDefault}
                      >
                        Export CSV
                      </Button>
                    </CSVLink>
                  </Col>
                </Row>

                {/* <Button
                  className=""
                  block
                  type="primary"
                  onClick={() => {
                    setIsModalOpen((prev) => !prev);
                  }}
                >
                  Notification
                </Button>  */}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ClubProfile

ClubProfile.defaultProps = {
  image: imageSrc,
  ownerName: "no data",
  clubUserName: "no data",
  name: "no data",
  bio: "no data",

};