import React, { useEffect, useRef } from 'react'
import { Col, Input, InputNumber, Row } from 'antd'
import moment from 'moment'
import CONSTANTS from '../../../../util/constant/CONSTANTS'
import useHttp from '../../../../hooks/use-http'
import { apiGenerator } from '../../../../util/functions'
import TextArea from 'antd/es/input/TextArea'
import { useState } from 'react'

const About = ({ info, setRefresh }) => {
    const api = useHttp()
    const debounceTimeout = useRef(null)


    // Update Notes
    const updateAmount = (value, id) => {
        // Clear the previous timeout if it exists
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to call the debounced function after a certain delay
        debounceTimeout.current = setTimeout(() => {
            const UPDATE_PAYOUT_STATUS = apiGenerator(CONSTANTS.API.updateEventById, { dataId: id })
            // console.log(id, value);
            api.sendRequest(UPDATE_PAYOUT_STATUS, () => {
                setRefresh(prev => !prev)
            }, {
                payOutAmount: value
            }, 'PayOut Amount Update Successfully')
        }, 800); // Adjust the delay as needed
    };
    // Update Notes
    const updateNotes = (value, id) => {
        // Clear the previous timeout if it exists
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to call the debounced function after a certain delay
        debounceTimeout.current = setTimeout(() => {
            const UPDATE_PAYOUT_STATUS = apiGenerator(CONSTANTS.API.updateEventById, { dataId: id })
            // console.log(id, value);
            api.sendRequest(UPDATE_PAYOUT_STATUS, () => {
                setRefresh(prev => !prev)
            }, {
                comments: value
            }, 'Notes Update Successfully')
        }, 800); // Adjust the delay as needed
    };
    const updatePayStatus = (id) => {
        const UPDATE_PAYOUT_STATUS = apiGenerator(CONSTANTS.API.updateEventById, { dataId: id })
        // console.log(id, value);
        api.sendRequest(UPDATE_PAYOUT_STATUS, () => {
            setRefresh(prev => !prev)
        }, {
            payOutStatus: 'processed'
        }, 'Payment Status Update Successfully')
    }

    return (
        <>
            <p className="font-bold m-0 ml-5 py-2 text-2xl">About Info</p>
            <Row>
                <Col span={12}>
                    <div className="mt-2 flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">
                            Creator
                        </p>
                        <p className="font-bold text-base">
                            &nbsp;: {info?.User?.name || info?.Club?.name || ''}
                        </p>
                    </div>
                    <div className="flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">
                            Details
                        </p>
                        <p className="font-bold text-base">
                            &nbsp;: {info?.description || ''}
                        </p>
                    </div>
                    <div className="flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">
                            Tag Artist
                        </p>
                        <p className="font-bold text-base">
                            &nbsp;: {info?.artistTags || ''}
                        </p>
                    </div>
                    <div className="flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">
                            Start Date & Time
                        </p>
                        <p className="font-bold text-base">
                            &nbsp;: {`${moment(info?.startDate).format(
                                "DD MMM"
                            )}, ${moment(info?.startDate).format("hh:mm: A")}`}
                        </p>
                    </div>

                    <div className="flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">
                            End Date & Time
                        </p>
                        <p className="font-bold text-base">
                            &nbsp;: {`${moment(info?.endDate).format(
                                "DD MMM"
                            )}, ${moment(info?.endDate).format("hh:mm: A")}`}
                        </p>
                    </div>
                    <div className="flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">
                            Venue Address
                        </p>
                        <p className="font-bold text-base">
                            &nbsp;: {info?.venue || ''}
                        </p>
                    </div>
                    <div className="flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">Fee</p>
                        <p className="font-bold text-base">
                            &nbsp;: ₹{info?.fees ?? ''}
                        </p>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">
                            Tickets
                        </p>
                        <p className="font-bold text-base">
                            &nbsp;: {info?.totalTickets || 0}
                        </p>
                    </div>
                    <div className="flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">
                            Attended
                        </p>
                        <p className="font-bold text-base">
                            &nbsp;: {info?.attended ?? 0}
                        </p>
                    </div>
                    <div className="flex items-center  gap-3 mb-4 ml-5">
                        <p className="font-semibold m-0 text-slate-400 text-base">
                            isPaid
                        </p>
                        <div className='mt-1'>
                            <Input
                                type="checkbox"
                                checked={info?.payOutStatus ? true : false}
                                onChange={() => {
                                    if (!info?.payOutStatus) {

                                        updatePayStatus(info?.id)

                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex ml-5">
                        <p className="font-semibold text-slate-400 text-base">
                            Paid Amount
                        </p>
                        <p className="font-bold flex text-base">
                            &nbsp;:&nbsp;₹&nbsp;
                            <InputNumber
                                placeholder={"Enter Payout Amount"}
                                onChange={(value) => {
                                    // const { value } = e.target
                                    console.log(value);
                                    updateAmount(value, info?.id)
                                }}
                                style={{
                                    width: "100%",
                                }}
                                value={info?.payOutAmount}

                            />
                        </p>
                    </div>
                    <div className="flex  gap-3 mb-4 ml-5">
                        <p className="font-semibold m-0 text-slate-400 text-base">
                            Notes
                        </p>
                        <div className=''>
                            <TextArea onChange={(e) => {
                                const { value } = e.target
                                updateNotes(value, info?.id)
                            }} rows={3} cols={40} defaultValue={info?.comments} />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default About

