import React from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import { bouncerExportData } from "../../../util/exportData";

const Bouncers = () => {
  return (
    <>
      <PageComponent
        tableTitle="Bouncers List"
        tableHeaders="BOUNCER"
        getAPI={CONSTANTS.API.getBouncers}
        extraResData="bouncers"
        getData={(res) => {
          // console.log(res);
          return res?.map((data) => {
            return {
              ...data,
              no: `B${data?.id.toString().padStart(7, "0")}`,
              clubName: data?.Club?.name,
              date: moment.utc(data?.createdAt).local().format('DD MMM, YYYY'),
              isBlockedD: data?.isBlocked ? 'Yes' : 'No'
            };
          });
        }}
        editformData
        editModalFields="EDIT_BOUNCER_MODAL"
        editModalTitle="Edit Bouncer"
        blockData
        editAPI={CONSTANTS.API.editBouncer}
        deleteData
        deleteAPI={CONSTANTS.API.deleteBouncer}
        searchAPI="/bouncers/search"
        isSearch
        searchfilter
        exportData={bouncerExportData}
        exportCSV
      />
    </>
  );
};

export default Bouncers;
