import React from 'react'
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PageComponent from '../../../../component/common/Page-Component';
import { clubPostExportData } from '../../../../util/exportData';

const Post = () => {
    const { id } = useParams();
    return (
        <div>

            <div>
                <PageComponent
                    tableTitle="Club Post List"
                    tableHeaders="CLUB_POST"
                    getAPI={CONSTANTS.API.getClubPost}
                    extraParams={`&ClubId=${id}`}
                    extraResData="posts"
                    getData={(res) => {
                        // console.log(res);
                        return res?.map(data => {
                            return {
                                ...data,
                                no: `P${data?.id.toString().padStart(7, "0")}`,
                                file: data?.thumbnail || data?.file,
                                createdAt: `${moment(data?.createdAt).format(
                                    "DD MMM, YYYY"
                                )}`,
                            };
                        });
                    }}
                    exportCSV
                    exportData={clubPostExportData}
                />
            </div>
        </div>
    )
}

export default Post