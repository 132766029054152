import {

  AiOutlineUser,
  AiOutlineSetting,
} from "react-icons/ai";
import { VscGraph } from "react-icons/vsc";
import { FaUserTie } from "react-icons/fa";
import { IoFastFoodOutline } from "react-icons/io5";
import { GiCarKey, GiMeshBall } from "react-icons/gi";
import { RiCustomerService2Fill, RiCalendarEventFill } from "react-icons/ri";
import { TbReport } from "react-icons/tb";
import { HiOutlineUserGroup } from "react-icons/hi";
const data = [
  {
    id: "dashboard",
    icon: <VscGraph />,
    label: "Dashboard",
  },
  // {
  //   id: "dashboard",
  //   icon: <AiOutlineDashboard />,
  //   label: "Dashboard",
  // },
  {
    id: "users",
    icon: <AiOutlineUser />,
    label: "Users",
  },
  {
    id: "clubs",
    icon: <GiMeshBall />,
    label: "Clubs",
  },
  {
    id: "events",
    icon: <RiCalendarEventFill />,
    label: "Events",
  },
  {
    id: "request",
    icon: <HiOutlineUserGroup />,
    label: "Request",
  },
  {
    id: "managers",
    icon: <FaUserTie />,
    label: "Managers",
  },
  {
    id: "bouncers",
    icon: <RiCustomerService2Fill />,
    label: "Bouncers",
  },
  {
    id: "valets",
    icon: <GiCarKey />,
    label: "Valets",
  },
  {
    id: "food-category",
    icon: <IoFastFoodOutline />,
    label: "Food Category",
  },
  {
    id: "report",
    icon: <TbReport />,
    label: "Report",
  },


  {
    id: "setting",
    icon: <AiOutlineSetting />,
    label: "Setting",
  },

];
export default data;
