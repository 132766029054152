import { Outlet, useNavigate } from "react-router-dom";

import { MenuOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Drawer, Image, Layout, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import Icon from "@ant-design/icons/lib/components/Icon";
import data from "../../util/constant/menu";
import CONSTANTS, { appRoot, loginRoot } from "../../util/constant/CONSTANTS";
import Profile from "../../asset/image/dummy-avatar.jpg";
import { getAuthToken } from "../../util/API/authStorage";
import { deleteAuthDetails } from "../../util/API/authStorage";
import logo from "../../asset/logos/icon.svg";
import useHttp from "../../hooks/use-http";

const { Header, Content, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  ...data.map((el) =>
    getItem(
      el.label,
      `/app/${el.id}`,
      el.icon,
      el.subMenu &&
      el.subMenu.map((elp) =>
        getItem(elp.label, `/app/${el.id}/${elp.id}`, elp.icon)
      )
    )
  ),
];
const AppLayout = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [UserData, setUserData] = useState({});
  // useEffect(() => {
  // const isLogin = getAuthToken() !== undefined && getAuthToken() !== null;
  // console.log(isLogin, "Login");
  // navigate(loginRoot);
  // }, [navigate]);
  const [collapsed, setCollapsed] = useState(true);
  const api = useHttp();
  useEffect(() => {
    if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
      navigate(loginRoot)
      return;
    }

    if (!CONSTANTS.GETMe) {
      api.sendRequest(CONSTANTS.API.getMe, (res) => {
        // console.log(res, "API");
        CONSTANTS.GETMe = res?.data?.admin;
        setUserData(res?.data?.admin);
      });
      // }
    }
  }, []);
  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };
  const onClose = () => {
    setVisible(false);
  };
  const handleLogout = () => {
    deleteAuthDetails();
    // localStorage.removeItem("token");
    localStorage.clear()
    navigate("/");
  };

  return (
    <>
      <Layout
        hasSider={true}
        style={{
          minHeight: "100vh",
          userSelect: "none",
        }}
      >
        <Sider
          trigger={null}
          // style={{
          //   overflow: "hidden",
          //   overflowY: "scroll",
          //   maxHeight: "100vh",
          // }}
          theme="light"
          collapsible
          collapsed={collapsed}
        >
          <div
            style={{
              display: "flex",
              height: "64px",
              paddingLeft: "30px",
              alignItems: "center",
            }}
          >
            <Icon
              style={{ fontSize: "20px" }}
              component={MenuOutlined}
              onClick={toggleCollapsed}
            ></Icon>
          </div>

          <Menu
            defaultSelectedKeys={window.location.pathname}
            activeKey=""
            mode="inline"
            items={items}
            onClick={(e) => {
              // console.log(e);
              if (e.keyPath.length) {
                navigate(e.key);
              }
            }}
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            style={{
              padding: 0,
              backgroundColor: "#FFFFFF",
              justifyContent: "space-between",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Row
              align="middle"
              justify="space-between"
              style={{ padding: "0px 35px" }}
            >
              <Col span={6} className="">
                <Image
                  style={{ height: "55px", width: "70px" }}
                  preview
                  src={logo}
                />
              </Col>

              <Col
                span={6}
              // style={{
              //   display: "flex",
              //   justifyContent: "end",
              //   alignItems: "center",
              // }}
              >
                <div className="flex items-center justify-end">
                  <div className=" text-lg mr-8 font-medium">
                    {UserData?.name || "Website Admin"}
                  </div>
                  <Avatar
                    src={Profile}
                    size={35}
                    style={{
                      border: "1px solid black",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setVisible(true);
                    }}
                  />
                </div>

                <Col
                  span={12}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {/* <Badge dot>
                    <IoNotificationsOutline
                      size="2em"
                      color="#BFC5D2"
                      display="flex"
                    />
                  </Badge> */}

                </Col>
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              margin: "0 16px",
            }}
          >
            <Outlet />

            {/* {getAuthToken() !== undefined && getAuthToken() !== null && (
              <Outlet />
            )} */}
          </Content>
        </Layout>
      </Layout>

      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
      >
        <div className="flex-x center text-center profile-drawer">
          <div>
            <Avatar
              size={100}
              style={{ color: "#fffff", backgroundColor: "#000000" }}
              className="mt-10"
              src={Profile}
            >
              <div style={{ fontWeight: "400", fontSize: "2rem" }}>
                {/* {UserData.fullname.split(" ")[0].charAt(0).toUpperCase()} */}
              </div>
            </Avatar>
            <div className="mt-5 text-2xl font-medium">
              {UserData?.name || "Website Admin"}
            </div>
            {/* <div className="an-24 regular-text mt20">{UserData?.siteName}</div> */}
            <div className="text-slate-500">
              {UserData?.email || "admin@test.com"}
            </div>
            <Button
              danger
              htmlType="submit"
              className="mt-5 w-40 h-10"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </div>
        <hr className="my30" style={{ background: "#E4E8F0" }} />

      </Drawer>

    </>
  );
};

export default AppLayout;
