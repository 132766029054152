import React, { useState } from 'react'
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import PageComponent from '../../../../component/common/Page-Component';
import moment from 'moment';
import useHttp from '../../../../hooks/use-http';
import { visitReportExportData } from '../../../../util/exportData';

const VisitLedger = () => {
    const api = useHttp()
    const [refresh, setRefresh] = useState(false)
    const blockUser = (id, checked) => {
        const USER_BLOCK_API = { ...CONSTANTS.API.editUser }
        USER_BLOCK_API.endpoint = USER_BLOCK_API.endpoint.replace(':dataId', id)
        const payload = {
            isBlocked: !checked
        }
        api.sendRequest(USER_BLOCK_API, () => {
            setRefresh(prev => !prev);
        }, payload)
    }

    const blockPost = (id, checked) => {
        const USER_POST_BLOCK_API = { ...CONSTANTS.API.blockVisitLedgerPost }
        USER_POST_BLOCK_API.endpoint = USER_POST_BLOCK_API.endpoint.replace(':dataId', id)
        const payload = {
            isBlocked: !checked
        }
        api.sendRequest(USER_POST_BLOCK_API, () => {
            setRefresh(prev => !prev);
        }, payload)
    }
    return (
        <>
            <div className="mt-5 mx-3">
                <PageComponent
                    tableHeaders="VISIT_LEDGER_REPORT"
                    tableTitle="Visit Ledger Report"
                    getAPI={CONSTANTS.API.getVisitLedgerReports}
                    extraResData="reportedLedgers"
                    getData={(res) => {
                        return res?.map(data => {
                            return {
                                ...data,
                                no: `VR${data?.VisitLedgerId.toString().padStart(7, "0")}`,
                                createdAt: moment.utc(data?.createdAt).local().format('DD MMM, YYYY'),
                                owner: data?.VisitLedger?.User?.name,
                                name: data?.User?.name || data?.Manager?.name || data?.Club?.name,
                                image: data?.VisitLedger?.image,
                                blockUser: {
                                    id: data?.VisitLedger?.User?.id,
                                    checked: data?.VisitLedger?.User?.isBlocked,
                                    onClick: blockUser
                                },
                                blockPost: {
                                    id: data?.VisitLedger?.id,
                                    checked: data?.VisitLedger?.isBlocked,
                                    onClick: blockPost
                                },
                                isUserBlockedD: data?.VisitLedger?.User?.isBlocked ? 'Block' : 'Un-block',
                                isPostBlockedD: data?.VisitLedger?.isBlocked ? 'Block' : 'Un-block',


                            }
                        })
                    }}
                    exportData={visitReportExportData}
                    reload={refresh}
                    exportCSV
                />
            </div>
        </>
    )
}

export default VisitLedger