import React from "react";
import PageComponent from "../../../../component/common/Page-Component";
import { useNavigate, useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import moment from "moment";
import { userChatHistoryExportData } from "../../../../util/exportData";





const ChatHistory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const viewChat = (dataId) => {
    navigate(`/app/users/${id}/${dataId}`);
  };

  return (
    <div>
      <div className="mt-5 mx-3">
        <PageComponent
          tableTitle="Chat History"
          tableHeaders="USERS_CHAT_HISTORY"
          extraEndPointData={`/${id}`}
          getAPI={CONSTANTS.API.getUserChatHistoryList}
          extraResData="chatRecords"
          getData={(res) => {
            // console.log(res);
            return res?.map(data => {
              if (data?.UserChats?.length !== 0) {
                return {
                  ...data,
                  no: `CH${data?.id.toString().padStart(7, "0")}`,
                  name: data?.UserChats[0]?.User1?.name || data?.UserChats[0]?.User2?.name,
                  image: data?.UserChats[0]?.User1?.profilePic || data?.UserChats[0]?.User2?.profilePic,
                  type: 'Users Chats',
                  date: `${moment.utc(data?.lastMessageOn).local()?.format('DD MMM,')}${moment.utc(data?.lastMessageOn).local()?.format('hh:mm A')}`,
                }
              }
              if (data?.UserManagerChats?.length !== 0) {
                return {
                  ...data,
                  no: `CH${data?.id.toString().padStart(7, "0")}`,
                  name: data?.UserManagerChats[0]?.Manager?.name,
                  image: data?.UserManagerChats[0]?.Manager?.profilePic,
                  type: 'User Manager Chats',
                  date: `${moment.utc(data?.lastMessageOn).local()?.format('DD MMM,')}${moment.utc(data?.lastMessageOn).local()?.format('hh:mm A')}`,
                }
              }
              if (Object.entries(data?.GroupChat)?.length !== 0) {
                return {
                  ...data,
                  no: `CH${data?.id.toString().padStart(7, "0")}`,
                  name: data?.GroupChat?.name,
                  image: data?.GroupChat?.profilePic,
                  type: 'Group Chats',
                  date: `${moment.utc(data?.lastMessageOn).local()?.format('DD MMM,')}${moment.utc(data?.lastMessageOn).local()?.format('hh:mm A')}`,
                }
              }
            });
          }}
          viewData
          viewFunction={viewChat}
          exportData={userChatHistoryExportData}
          exportCSV
        />
      </div>
    </div>
  );
};

export default ChatHistory;
