import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row, Switch } from "antd";
import imageSrc from "../../../asset/image/image 2.png";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../util/functions";
import { useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";

const UserProfile = ({
  name,
  userName,
  profilePic,
  privateAcc,
  isBlocked,
  setRefresh,
  fcm
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const api = useHttp();
  const { id } = useParams();
  const dataId = id;
  // console.log(fcm);

  const notificationHandler = (value) => {
    const payload = {
      ...value,
      fcm,
    }
    // console.log(payload);
    const SENT_NOTIFICATION_API = { ...CONSTANTS.API.sentNotification }
    api.sendRequest(SENT_NOTIFICATION_API, () => {
      setIsModalOpen(prev => !prev)
    }, payload, 'Sent Notification Sucessfully!!!')
  };

  const blockUser = (value) => {
    // console.log(value);
    const payload = {
      isBlocked: value,
    };
    const BLOCK_USER_API = apiGenerator(CONSTANTS.API.editUser, {
      dataId,
    });
    api.sendRequest(
      BLOCK_USER_API,
      () => {
        setRefresh((prev) => !prev);
      },
      payload
    );
  };

  return (
    <Card className="h-72 select-text">
      <div>
        <Row className=" ml-5 mt-5">
          <Col span={7}>
            <Image
              width={80}
              height={80}
              className="rounded-full object-cover"
              preview
              src={profilePic}
              alt="Images is Not Availabel"
            />
          </Col>
          <Col span={17}>
            <p className="font-bold mt-4  ml-5 text-2xl">{name}</p>
            <p className="font-bold text-sm  ml-5 -mt-6 text-slate-400">
              {userName}
            </p>
          </Col>
        </Row>
        <ModalFormCreator
          open={isModalOpen}
          name="Personal Notification"
          menu="NOTIFICATION_MODAL"
          onCreate={notificationHandler}
          onCancel={() => {
            setIsModalOpen((prev) => !prev);
          }}
        />
        <div className="mt-7">
          <Row className="">
            <Col span={12}>
              <div className="">
                <p className="text-xl pl-3 font-bold">
                  {privateAcc ? "Private Account" : ""}
                </p>
              </div>
            </Col>
            <Col span={12}>
              <div className="float-right">
                <p className="text-xl font-bold inline-block">Block</p>
                <Switch
                  size="small"
                  className="ml-2 -mt-1"
                  checked={isBlocked}
                  onChange={blockUser}
                />
              </div>
            </Col>
          </Row>
          <Button
            className=""
            block
            type="primary"
            onClick={() => {
              setIsModalOpen((prev) => !prev);
            }}
          >
            Notification
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default UserProfile;

UserProfile.defaultProps = {
  name: "",
  userName: "",
  privateAcc: "",
  privateAcc: false,
  profilePic: imageSrc,
};