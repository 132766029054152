import React from 'react'
import Manager from './staff/manager';
import Bouncer from './staff/bouncer';
import Valet from './staff/valet';
import { Card, Tabs } from 'antd';
import CONSTANTS from '../../../../util/constant/CONSTANTS';

const Staffs = () => {
  const sub_menu = {
    managers: <Manager />,
    bouncer: <Bouncer />,
    valets: <Valet />,
  }



  return (
    <>
      <Card className="mt-5">
        <Tabs
          className="p-0 m-0"
          defaultActiveKey="1"
          size="middle"
          type="card"
          items={CONSTANTS.TAB_MENU.STAFF.map((ele) => {
            return {
              label: `${ele.Label}`,
              key: `${ele.id}`,
              children: sub_menu[ele.id],
            };
          })}
        />
      </Card>
    </>
  );
}

export default Staffs