import React from "react";
import PageComponent from "../../../../component/common/Page-Component";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import moment from "moment";
import { userCarValetExportData } from "../../../../util/exportData";

const CarValet = () => {
  const { id } = useParams()
  return (
    <div className="mt-5 mx-3">
      <PageComponent
        tableHeaders="USERS_CAR_VALET"
        tableTitle="Car Valet List"
        getAPI={CONSTANTS.API.getUserValetParking}
        extraParams={`&UserId=${id}`}
        extraResData="parkingRequests"
        getData={(res) => {
          // console.log(res);
          return res?.map(data => {
            return {
              ...data,
              no: `V${data?.id.toString().padStart(7, "0")}`,
              name: data?.Club?.name,
              keyNumber: data?.CarQrId,
              dateTime: `${moment.utc(data?.createdAt).local().format("DD MMM")}, ${moment.utc(data?.createdAt).local().format("hh:mm A")}`,
            }
          })
        }}
        exportData={userCarValetExportData}
        exportCSV
      />
    </div>
  );
};

export default CarValet;
