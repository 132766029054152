import Eye from "../../asset/image/eye.png";
import { Button, Image, Input, Popconfirm, Switch } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import logo from "../../asset/logos/icon.svg";
import moment from "moment/moment";

const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};
const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

// Delete Button
const RenderDeleteButton = (value) => {
  const { id, onClick } = value;
  return (
    <Popconfirm title="Sure to delete?" onConfirm={() => onClick(id)}>
      <Button type="primary">
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Sure to delete?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
const RenderDownloadButton = (value) => {
  return (
    <Button
      type="primary"
      onClick={() => {
        window.open(value, "Download");
      }}
    >
      <DownloadOutlined />
    </Button>
  );
};

// Edit Button
const RenderEditButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

// View Image Button
const RenderViewImageButton = (value) => {
  return (
    <Image
      width={20}
      src={Eye}
      className="pointer"
      preview={{
        mask: false,
        src: value,
      }}
    />
  );
};

// Render Accept and Reject Button
const RenderAcceptRejectButton = (value) => {
  const { id, onClick } = value;

  return (
    <div>
      <Popconfirm
        placement="top"
        title={"Are you sure to Accept this request?"}
        // description={description}
        onConfirm={() => {
          onClick(id, true);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#52c41a" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Button>
      </Popconfirm>
      <Popconfirm
        placement="top"
        title={"Are you sure to Reject this request?"}
        // description={description}
        onConfirm={() => {
          onClick(id, false);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button className="ml-2 bg-red-600">
          <CloseCircleTwoTone twoToneColor="#dc2626" />
        </Button>
      </Popconfirm>
    </div>
  );
};

// Render View Button
const RenderViewButton = (value) => {
  const { id, onClick, disable = false } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      className={disable ? 'cursor-not-allowed' : 'cursor-pointer'}
      disabled={disable}
      type="primary"
    >
      <EyeOutlined
        className={disable ? 'cursor-not-allowed' : 'cursor-pointer'}
        onClick={() => {
          onClick(id);
        }}
      />
    </Button>
  );
};

// Render Images
const ImageRanders = (value) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <div>
      <Image
        width={50}
        height={50}
        className="rounded-full object-cover"
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};

// Toggle Button
const RenderToggleButton = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Switch
        onChange={() => {
          onClick(id, checked);
        }}
        checked={checked}
      />
    </div>
  );
};

// Checkbox render
const RenderCheckbox = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Input
        type="checkbox"
        checked={!checked}
        onChange={() => {
          onClick(id, checked);
        }}
      />
    </div>
  );
};

export const loginRoot = "/";
export const appRoot = "/app";


const CONSTANTS = {
  BASE_URL: "http://20.244.39.211/api/v1",
  GETMe: null,
  TABLE: {
    USERS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
      },
      {
        title: "Profile",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 130,
      },

      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
        width: 150,
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
        width: 150,
      },

      {
        title: "Private account",
        dataIndex: "checkbox",
        render: RenderCheckbox,
        width: 150,
      },

      {
        title: "Block User",
        dataIndex: "toggle",
        render: RenderToggleButton,
        width: 100,
      },

      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 100,
      },
    ],
    WAIT_USERS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 70,
      },

      {
        title: "Mobile No.",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
        width: 240,
      },

      {
        title: "Signup Date",
        dataIndex: "date",
        width: 80,
      },



    ],
    USERS_BOOKING: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Club Name",
        dataIndex: "club",
        width: 120,
      },
      {
        title: "Event Name",
        dataIndex: "eventName",
        width: 140,
      },
      {
        title: "Date & Time",
        dataIndex: "date",
        width: 140,
      },
      {
        title: "Entries",
        dataIndex: "entries",
        sorter: (a, b) => alphanumericSort(a, b, "entries"),
        width: 100,
      },
      {
        title: "Total Amount",
        dataIndex: "total",
        width: 140,
      },


    ],
    USERS_CAR_VALET: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Club Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Key Number",
        dataIndex: "keyNumber",
        sorter: (a, b) => alphanumericSort(a, b, "keyNumber"),
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
      },
      {
        title: "Request Id",
        dataIndex: "id",
        sorter: (a, b) => alphanumericSort(a, b, "id"),
      },
      {
        title: "Date & Time",
        dataIndex: "dateTime",
        sorter: (a, b) => moment(a.dateTime).subtract(moment(b.dateTime)),
      },
    ],
    USERS_CHAT_HISTORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Type",
        dataIndex: "type",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Date & Time",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Chat View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERS_POST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 70,
      },
      {
        title: "Club Name",
        dataIndex: "clubName",
        width: 100,
      },

      {
        title: "Date & Time",
        dataIndex: "date",
        width: 100,
      },
      {
        title: "Likes",
        dataIndex: "likeCount",
        width: 60,
      },
      {
        title: "Comment",
        dataIndex: "commentCount",
        width: 100,
      },
      {
        title: "Caption",
        dataIndex: "experience",
        sorter: (a, b) => alphanumericSort(a, b, "experience"),
        width: 190,
      },
      {
        title: "Like List",
        dataIndex: "likeList",
        render: RenderViewButton,
        width: 80,
      },
      {
        title: "Comment List",
        dataIndex: "commentList",
        render: RenderViewButton,
        width: 100,
      },

    ],
    REQUEST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
      },

      {
        title: "Club Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Owner Name",
        dataIndex: "ownerName",
        sorter: (a, b) => alphanumericSort(a, b, "ownerName"),
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },
      {
        title: "Action",
        dataIndex: "action",
        render: RenderAcceptRejectButton,
      },

    ],
    CLUBS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
      },
      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
      },
      {
        title: "Club Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Owner Name",
        dataIndex: "ownerName",
        sorter: (a, b) => alphanumericSort(a, b, "ownerName"),
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },
      {
        title: "Delete",
        dataIndex: "delete",
        render: RenderDeleteButton,
      },
    ],
    EVENTS_USER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Profile",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },

      {
        title: "User",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 130,
      },
      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
        width: 130,
      },

    ],
    EVENTS_TRANSACTION: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 80,
      },
      {
        title: "Profile",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 80,
      },

      {
        title: "Buyer",
        dataIndex: "name",
        width: 100,
      },
      {
        title: "Entries",
        dataIndex: "entries",
        width: 100,
      },
      {
        title: "Fees",
        dataIndex: "fees",
        sorter: (a, b) => alphanumericSort(a, b, "fees"),
        width: 100,
      },
      {
        title: "Convenience",
        dataIndex: "convenience",
        sorter: (a, b) => alphanumericSort(a, b, "convenience"),
        width: 100,
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        width: 100,
      },
      {
        title: "CreatedAt",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
        width: 110,
      },

    ],
    CLUB_EVENTS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 70,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 90,
      },
      {
        title: "Event Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 130,
      },
      {
        title: "User Name",
        dataIndex: "$userName",
        width: 130,
      },
      {
        title: "Date",
        dataIndex: "date",
        width: 120,
      },

      {
        title: "Time",
        dataIndex: "time",
        width: 100,
      },
      {
        title: "Fees",
        dataIndex: "fees",
        sorter: (a, b) => alphanumericSort(a, b, "fees"),
        width: 70,
      },
      // {
      //   title: "isPaid",
      //   dataIndex: "paid",
      //   render: RenderCheckbox,
      //   width: 100,
      // },
      {
        title: "Paid Amount",
        dataIndex: "payOutAmount",
        sorter: (a, b) => alphanumericSort(a, b, "payOutAmount"),
        width: 120,
      },
      {
        title: "Revenue",
        dataIndex: "total_revenue",
        sorter: (a, b) => alphanumericSort(a, b, "revenue"),
        width: 100,
      },
      {
        title: "Event",
        dataIndex: "event",
        width: 110,
      },
    ],
    CLUBS_STAFF: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Club Name",
        dataIndex: "clubName",
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },
      {
        title: "Staffs",
        dataIndex: "staffs",
      },
      {
        title: "Account Created",
        dataIndex: "date",
      },

    ],
    CLUB_VISIT_LEDGER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 70,
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 60,
      },
      {
        title: "Event",
        dataIndex: "name",
        width: 100,
      },
      {
        title: "User",
        dataIndex: "userName",
        width: 100,
      },

      {
        title: "Date",
        dataIndex: "date",
        width: 100,
      },

      {
        title: "Time",
        dataIndex: "time",
        width: 90,
      },
      {
        title: "Description",
        dataIndex: "experience",
        sorter: (a, b) => alphanumericSort(a, b, "experience"),
        width: 200,
      },

    ],
    CLUB_FLOATING_ICON_MENU: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 70,
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 60,
      },
      {
        title: "Food Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 70,
      },
      {
        title: "Price",
        dataIndex: "price",
        sorter: (a, b) => alphanumericSort(a, b, "price"),
        width: 70,
      },
      {
        title: "Discount",
        dataIndex: "discount",
        sorter: (a, b) => alphanumericSort(a, b, "discount"),
        width: 90,
      },
      {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
        width: 200,
      },

    ],
    CLUB_FLOATING_ICON_BANNER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 90,
      },

      {
        title: "Banner",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Dimensions",
        dataIndex: "dimensions",
        sorter: (a, b) => alphanumericSort(a, b, "dimensions"),
        width: 100,
      },
      {
        title: "Navigation Link",
        dataIndex: "linkD",
        sorter: (a, b) => alphanumericSort(a, b, "link"),
        width: 250,
      },

    ],
    CLUB_FLOATING_ICON_CAR_VALET: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Key Number",
        dataIndex: "keyNumber",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Request Id",
        dataIndex: "id",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Date & Time",
        dataIndex: "dateTime",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    CLUB_POST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Post",
        dataIndex: "file",
        render: ImageRanders,
        width: 100,
      },

      {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
        width: 470,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        sorter: (a, b) => moment(a.createdAt).subtract(moment(b.createdAt)),
        width: 100,
      },
    ],
    MANAGER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 90,
      },

      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
        width: 70,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 120,
      },
      {
        title: "Club Name",
        dataIndex: "clubName",
        width: 120,
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
        width: 100,
      },
      {
        title: "Date",
        dataIndex: "date",
        width: 100,
      },
      {
        title: "Block Manager",
        dataIndex: "toggle",
        render: RenderToggleButton,
        width: 100,
      },
      // {
      //   title: "Edit",
      //   dataIndex: "edit",
      //   render: RenderEditButton,
      //   width: 60,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "delete",
      //   render: RenderDeleteButton,
      //   width: 100,
      // },
    ],
    BOUNCER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 90,
      },

      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
        width: 70,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 120,
      },
      {
        title: "Club Name",
        dataIndex: "clubName",
        width: 120,
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
        width: 100,
      },
      {
        title: "Date",
        dataIndex: "date",
        width: 100,
      },
      {
        title: "Block Bouncer",
        dataIndex: "toggle",
        render: RenderToggleButton,
        width: 100,
      },
      // {
      //   title: "Edit",
      //   dataIndex: "edit",
      //   render: RenderEditButton,
      //   width: 60,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "delete",
      //   render: RenderDeleteButton,
      //   width: 100,
      // },
    ],
    VALETS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 90,
      },

      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
        width: 70,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 120,
      },
      {
        title: "Club Name",
        dataIndex: "clubName",
        width: 120,
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
        width: 100,
      },
      {
        title: "Date",
        dataIndex: "date",
        width: 100,
      },
      {
        title: "Block Valet",
        dataIndex: "toggle",
        render: RenderToggleButton,
        width: 100,
      },
      // {
      //   title: "Edit",
      //   dataIndex: "edit",
      //   render: RenderEditButton,
      //   width: 60,
      // },
      // {
      //   title: "Action",
      //   dataIndex: "delete",
      //   render: RenderDeleteButton,
      //   width: 100,
      // },
    ],

    USER_EVENT: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 90,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 60,
      },
      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
        width: 60,
      },
      {
        title: "Event Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Club Name",
        dataIndex: "clubName",
        sorter: (a, b) => alphanumericSort(a, b, "clubName"),
        width: 100,
      },
      {
        title: "Start Date & Time",
        dataIndex: "startDateTime",
        sorter: (a, b) => moment(a.startDateTime).subtract(moment(b.startDateTime)),
        width: 120,
      },
      {
        title: "End Date & Time",
        dataIndex: "endDateTime",
        sorter: (a, b) => moment(a.endDateTime).subtract(moment(b.endDateTime)),
        width: 120,
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 80,
      },
    ],
    CLUB_EVENT: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 90
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 60
      },
      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
        width: 60
      },
      {
        title: "Event Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100
      },
      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
        width: 100
      },
      {
        title: "Start Date & Time",
        dataIndex: "startDateTime",
        sorter: (a, b) => moment(a.startDateTime).subtract(moment(b.startDateTime)),
        width: 120
      },
      {
        title: "End Date & Time",
        dataIndex: "endDateTime",
        sorter: (a, b) => moment(a.endDateTime).subtract(moment(b.endDateTime)),
        width: 120
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        width: 80
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 80
      },
    ],
    FOOD_CATEGORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Food Category",
        dataIndex: "category",
        sorter: (a, b) => alphanumericSort(a, b, "category"),
        width: 550,
      },
      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 60,
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 80,
      },
    ],

    USER_REPORT: [{
      title: "No.",
      dataIndex: "no",
      sorter: (a, b) => alphanumericSort(a, b, "no"),
      width: 90,
    },
    {
      title: "View",
      dataIndex: "viewData",
      render: RenderViewButton,
      width: 60,
    },
    {
      title: "Reported User",
      dataIndex: "reportedUserProfile",
      render: ImageRanders,
      width: 80,
    },
    {
      title: "Reported User Name",
      dataIndex: "reportedUser",
      sorter: (a, b) => alphanumericSort(a, b, "reportedUser"),
      width: 100,
    },
    {
      title: "Reporting User",
      dataIndex: "reportingUserProfile",
      render: ImageRanders,
      width: 80,
    },
    {
      title: "Reporting User Name",
      dataIndex: "reportingUser",
      sorter: (a, b) => alphanumericSort(a, b, "reportingUser"),
      width: 100,
    },
    {
      title: "Block Reported User",
      dataIndex: "block",
      render: RenderToggleButton,
      width: 80,
    }
    ],

    POST_REPORT: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 90,
      },
      {
        title: "View",
        dataIndex: "viewData",
        render: RenderViewButton,
        width: 60,
      },

      {
        title: "Club Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "User Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
        width: 100,
      },
      {
        title: "Reported Post",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Block Reported Post",
        dataIndex: "blockPost",
        render: RenderToggleButton,
        width: 120,
      },
    ],
    VISIT_LEDGER_REPORT: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Profile",
        dataIndex: "image",
        render: ImageRanders,
        width: 100
      },

      {
        title: "Reporting User",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Ledger Owner",
        dataIndex: "owner",
        sorter: (a, b) => alphanumericSort(a, b, "owner"),
        width: 150,
      },
      {
        title: "Reason",
        dataIndex: "reason",
        width: 200,
      },
      {
        title: "Report Date",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
        width: 140,
      },
      {
        title: "Block Reported User",
        dataIndex: "blockUser",
        render: RenderToggleButton,
        width: 120,
      },
      {
        title: "Block Reported Post",
        dataIndex: "blockPost",
        render: RenderToggleButton,
        width: 120,
      }

    ],


  },
  FORM_FIELD: {
    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
    USERS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Your Name",
        required: true,
      },

      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number",
        required: true,
        // width: 24,
      },
      {
        no: 3,
        Label: "Username",
        name: "user",
        id: "user",
        type: "text",
        placeholder: "Enter User Name",
        required: true,
      },

      {
        no: 4,
        Label: "Date of Birth",
        name: "date",
        id: "date",
        type: "date",
        placeholder: "Enter Date of Birth",
        required: true,
      },
      {
        no: 5,
        Label: "Profile Picture",
        name: "profilePic",
        id: "profilePic",
        type: "file",
        placeholder: " ",
        required: true,
      },
    ],
    NOTIFICATION_MODAL: [
      {
        no: 1,
        Label: "Notification Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Notification Title",
        required: true,
      },

      {
        no: 2,
        Label: "Message",
        name: "body",
        id: "body",
        type: "text",
        placeholder: "Enter Message",
        required: true,
        // width: 24,
      },
    ],
    CLUB_MODAL: [
      {
        no: 1,
        Label: "Club Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Club Name",
        required: true,
      },
      {
        no: 2,
        Label: "Owner Name",
        name: "ownerName",
        id: "ownerName",
        type: "text",
        placeholder: "Enter Owner Name",
        required: true,
      },
      {
        no: 3,
        Label: "Mobile",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number",
        required: true,
      },
      {
        no: 4,
        Label: "Club Type",
        name: "type",
        id: "type",
        type: "text",
        placeholder: "Enter Club Type",
        required: true,
      },
      {
        no: 5,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        required: false,
      },
    ],
    CLUB_FLOATING_ICON_MENU_MODAL: [
      {
        no: 1,
        Label: "Food Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Food Name",
        required: true,
      },
      {
        no: 2,
        Label: "Price",
        name: "price",
        id: "price",
        type: "number",
        placeholder: "Enter Price",
        required: true,
      },
      {
        no: 3,
        Label: "Discount",
        name: "discount",
        id: "discount",
        type: "number",
        placeholder: "Enter Discount(%)",
        required: true,
      },

      {
        no: 5,
        Label: "Food Image",
        name: "image",
        id: "image",
        type: "file",
        required: false,
      },
    ],
    EDIT_CLUB_FLOATING_ICON_MENU_MODAL: [
      {
        no: 1,
        Label: "Food Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Food Name",
        required: true,
      },
      {
        no: 2,
        Label: "Price",
        name: "price",
        id: "price",
        type: "number",
        placeholder: "Enter Price",
        required: true,
      },
      {
        no: 3,
        Label: "Discount",
        name: "discount",
        id: "discount",
        type: "number",
        placeholder: "Enter Discount(%)",
        required: true,
      },

      {
        no: 5,
        Label: "Food Image",
        name: "image",
        id: "image",
        type: "file",
        required: false,
      },
    ],
    CLUB_FLOATING_ICON_BANNER_MODAL: [
      {
        no: 1,
        Label: "Food Image",
        name: "image",
        id: "image",
        type: "file",
        required: true,
      },
    ],
    EDIT_CLUB_FLOATING_ICON_BANNER_MODAL: [
      {
        no: 1,
        Label: "Food Image",
        name: "image",
        id: "image",
        type: "file",
        required: true,
      },
    ],
    FOOD_CATEGORY_MODAL: [
      {
        no: 1,
        Label: "Food Category",
        name: "category",
        id: "category",
        type: "text",
        placeholder: "Enter Food Category",
        required: true,
      },


    ],
    EDIT_MANAGER_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Bouncer Name",
        required: true,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number ",
        required: true,
      },
      {
        no: 3,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: false,
      },
    ],
    EDIT_BOUNCER_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Bouncer Name",
        required: true,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number ",
        required: true,
      },
      {
        no: 3,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: false,
      },
    ],
    EDIT_VALETS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Valet Name",
        required: true,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number ",
        required: true,
      },
      {
        no: 3,
        Label: "Profile Picture",
        name: "image",
        id: "image",
        type: "file",
        placeholder: "",
        required: false,
      },
    ],
  },

  API: {
    // Login API
    login: {
      type: "POST",
      endpoint: "/admins/login",
    },
    // Get Me API
    getMe: {
      type: "GET",
      endpoint: "/admins/my/profile",
    },

    // Analytics API
    getAnalytics: {
      type: "GET",
      endpoint: "/admins/global/analytics",
    },
    // Request API
    getClubRequest: {
      type: "GET",
      endpoint: "/admins/clubs?inCompleted=1&kycStatus=-1",
    },
    getOneClubRequest: {
      type: "GET",
      endpoint: "/admins/clubs/id/:requestId",
    },
    acceptOrRejectRequest: {
      type: "PATCH",
      endpoint: "/admins/clubs/kyc-status",
    },
    // Users API
    getUsers: {
      type: "GET",
      endpoint: "/admins/users/",
    },
    getIncompleteUsers: {
      type: "GET",
      endpoint: "/admins/users/?inCompleted=1",
    },
    getOneUser: {
      type: "GET",
      endpoint: "/admins/users/id/:dataId",
    },
    editUser: {
      type: "PATCH",
      endpoint: "/admins/users/block/:dataId",
    },
    deleteUsers: {
      type: "DELETE",
      endpoint: "/admins/users/id/:dataId",
    },
    getWaitListUsers: {
      type: "GET",
      endpoint: "/admins/waitlist",
    },

    // Users  -> Blocked API
    getUserBlockedBy: {
      type: "GET",
      endpoint: "/blocklist/users/blockedby",
    },
    // Users  -> Follwers API
    getUserFollowers: {
      type: "GET",
      endpoint: "/friends/followers",
    },

    // Users  -> Following API
    getUserFollowing: {
      type: "GET",
      endpoint: "/friends/followings",
    },

    // Users  -> Booking API
    getUserBooking: {
      type: "GET",
      endpoint: "/bookings/user",
    },
    // Users  -> Chat History API
    getUserChatHistoryList: {
      type: "GET",
      endpoint: "/chat/records/users",
    },
    // Users  -> Chat API
    getChats: {
      type: "GET",
      // endpoint: `/chat/records/messages`,
      endpoint: `/chat/records/messages`,
    },

    // Users  -> Visits API
    getVisits: {
      type: "GET",
      endpoint: `/visitledgers/by/users`,
    },

    // Users  -> Like API 
    getLikeList: {
      type: "GET",
      endpoint: `/visitledgers/likes/`,
    },
    // Users  -> Comments API
    getCommentList: {
      type: "GET",
      endpoint: `/visitledgers/comments/`,
    },
    // Users  -> Reply API
    getReplyList: {
      type: "GET",
      endpoint: `/visitledgers/comments/replies/`,
    },

    // Users ->  Valet (Parking) API
    getUserValetParking: {
      type: "GET",
      endpoint: "/parkings/request/users",
    },

    // Post and Report Api

    getUserReports: {
      type: "GET",
      endpoint: "/reports/users",
    },
    getPostReports: {
      type: "GET",
      endpoint: "/reports/posts",
    },
    getVisitLedgerReports: {
      type: "GET",
      endpoint: "/reported/ledgers",
    },
    blockVisitLedgerPost: {
      type: "PATCH",
      endpoint: "/visitledgers/block/unblock/:dataId",
    },
    blockPost: {
      type: "PATCH",
      endpoint: "/posts/block/unblock/:dataId",
    },
    blockVisitLedgerUser: {
      type: "POST",
      endpoint: "/blocklist/users/block/:dataId",
    },

    // Clubs API
    getClubs: {
      type: "GET",
      endpoint: "/admins/clubs?kycStatus=1",
    },
    getIncompleteClubs: {
      type: "GET",
      endpoint: "/admins/clubs?inCompleted=1",
    },
    getOneClub: {
      type: "GET",
      endpoint: "/admins/clubs/id/:dataId",
    },
    editClubs: {
      type: "PATCH",
      endpoint: "/admins/clubs/id/:dataId",
    },
    deleteClubs: {
      type: "DELETE",
      endpoint: "/admins/clubs/id/:dataId",
    },

    // Clubs -> Event API
    getClubsEvent: {
      type: "GET",
      endpoint: "/admins/events?status=1",
    },
    getClubsEventById: {
      type: "GET",
      endpoint: "/events/id/:dataId",
    },
    updateEvent: {
      type: "PATCH",
      endpoint: "/events/id/:dataId",
    },
    updateEventById: {
      type: "PATCH",
      endpoint: "/events/payout/status/:dataId",
    },
    // Clubs -> Staff Manager API
    getClubManager: {
      type: "GET",
      endpoint: "/managers/",
    },

    getClubValet: {
      type: "GET",
      endpoint: "/valets",
    },

    // Clubs -> Food  API
    getClubFood: {
      type: "GET",
      endpoint: "/foods/",
    },
    // Clubs -> Banner  API
    getClubBanner: {
      type: "GET",
      endpoint: "/banners",
    },
    // Clubs ->  Valet (Parking) API
    getClubValetParking: {
      type: "GET",
      endpoint: "/parkings/request/clubs",
    },
    // Clubs ->  Visit Ledger  API
    getClubVisitLedger: {
      type: "GET",
      endpoint: "/visitledgers/clubs",
    },
    // Clubs ->  Club Post  API
    getClubPost: {
      type: "GET",
      endpoint: "/admins/posts",
    },
    // Valets Navigation API
    getValets: {
      type: "GET",
      endpoint: `/valets/`,
    },
    addValets: {
      type: "POST",
      endpoint: "/valets",
    },
    editValets: {
      type: "PATCH",
      endpoint: "/valets/id/:dataId",
    },
    deleteValets: {
      type: "DELETE",
      endpoint: "/valets/id/:dataId",
    },

    // Food Category  API
    getFoodCategory: {
      type: "GET",
      endpoint: "/categories/food",
    },
    addFoodCategory: {
      type: "POST",
      endpoint: "/categories/food",
    },
    editFoodCategory: {
      type: "PATCH",
      endpoint: "/categories/food/id/:dataId",
    },
    deleteFoodCategory: {
      type: "DELETE",
      endpoint: "/categories/food/id/:dataId",
    },

    // Manager API
    getManager: {
      type: "GET",
      endpoint: "/managers",
    },
    deleteManager: {
      type: "DELETE",
      endpoint: "/managers/id/:dataId",
    },
    editManager: {
      type: "PATCH",
      endpoint: "/managers/id/:dataId",
    },
    // Bouncer API
    getBouncers: {
      type: "GET",
      endpoint: "/bouncers",
    },
    deleteBouncer: {
      type: "DELETE",
      endpoint: "/bouncers/id/:dataId",
    },
    editBouncer: {
      type: "PATCH",
      endpoint: "/bouncers/id/:dataId",
    },


    // Events API
    getUserEvent: {
      type: "GET",
      endpoint: "/bouncers",
    },

    getOneEvent: {
      type: "GET",
      endpoint: "/events/dashboard/id/:dataId",
    },

    getParticipateUser: {
      type: "GET",
      endpoint: "/tickets/participants/byevent",
    },
    getEventTransaction: {
      type: "GET",
      endpoint: "/bookings/transactions",
    },

    // Notification API
    sentNotification: {
      type: "POST",
      endpoint: "/global/notification/individual",
    },
    // Particular Event Notification API
    sentEventNotification: {
      type: "POST",
      endpoint: "/events/notify/participants",
    },

    // Setting API
    getAppConfig: {
      type: "GET",
      endpoint: "/appconfigs",
    },
    setAppConfig: {
      type: "PATCH",
      endpoint: "/admins/appconfigs/",
    },


  },

  TAB_MENU: {
    USER_TABS: [
      {
        no: 1,
        Label: "Users",
        name: "users",
        id: "users",
      },
      {
        no: 2,
        Label: "WaitList",
        name: "waitlist",
        id: "waitlist",
      },
      {
        no: 3,
        Label: "Incompleted Profile",
        name: "incompleted",
        id: "incompleted",
      },
    ],
    USER: [
      {
        no: 1,
        Label: "About Info",
        name: "about",
        id: "about",
      },
      {
        no: 2,
        Label: "Previous Bookings",
        name: "booking",
        id: "booking",
      },
      {
        no: 3,
        Label: "Car valet",
        name: "valet",
        id: "valet",
      },
      {
        no: 4,
        Label: "Chat History",
        name: "chat",
        id: "chat",
      },
      {
        no: 5,
        Label: "Visits",
        name: "post",
        id: "post",
      },
    ],
    CLUB_TABS: [
      {
        no: 1,
        Label: "Clubs",
        name: "clubs",
        id: "clubs",
      },

      {
        no: 3,
        Label: "Incompleted Profile",
        name: "incompleted",
        id: "incompleted",
      },
    ],
    CLUB: [
      {
        no: 1,
        Label: "About Info",
        name: "about",
        id: "about",
      },
      {
        no: 2,
        Label: "Events",
        name: "events",
        id: "events",
      },
      {
        no: 3,
        Label: "Staffs",
        name: "staff",
        id: "staff",
      },
      {
        no: 4,
        Label: "Visit Ledger",
        name: "ledger",
        id: "ledger",
      },
      {
        no: 5,
        Label: "Floating Icon",
        name: "icon",
        id: "icon",
      },
      {
        no: 5,
        Label: "Post",
        name: "post",
        id: "post",
      },

    ],
    CLUB_FLOATING_ICON: [
      {
        no: 0,
        Label: "Menu",
        name: "menu",
        id: "menu",
      },
      {
        no: 1,
        Label: "Banners",
        name: "banner",
        id: "banner",
      },
      {
        no: 2,
        Label: "Car Valet",
        name: "valet",
        id: "valet",
      },
    ],
    USER_EVENT: [
      {
        no: 1,
        Label: "About Info",
        name: "info",
        id: "info",
      },
      {
        no: 2,
        Label: "Bookings",
        name: "booking",
        id: "booking",
      },

    ],
    CLUB_EVENT: [
      {
        no: 1,
        Label: "About Info",
        name: "info",
        id: "info",
      },
      {
        no: 2,
        Label: "Bookings",
        name: "booking",
        id: "booking",
      },

    ],
    STAFF: [
      {
        no: 0,
        Label: "Managers",
        name: "managers",
        id: "managers",
      },
      {
        no: 0,
        Label: "Bouncer",
        name: "bouncer",
        id: "bouncer",
      },
      {
        no: 0,
        Label: "Valets",
        name: "valets",
        id: "valets",
      },

    ],
    REPORT: [
      {
        no: 0,
        Label: "User",
        name: "user",
        id: "user",
      },
      {
        no: 1,
        Label: "Post",
        name: "post",
        id: "post",
      },
      {
        no: 1,
        Label: "Visit Ledger",
        name: "ledger",
        id: "ledger",
      },


    ],
    EVENT: [
      {
        no: 0,
        Label: "About Info",
        name: "about",
        id: "about",
      },
      {
        no: 1,
        Label: "Participate Users",
        name: "users",
        id: "users",
      },
      {
        no: 2,
        Label: "Transaction",
        name: "transaction",
        id: "transaction",
      },


    ],
  },
};
export default CONSTANTS;
