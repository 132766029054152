import React, { useEffect, useState } from "react";
import { Card, Col, Empty, Image, Modal, Pagination, Row } from "antd";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useParams } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";
import Heading from "../../../component/common/Heading";

const UserProfileDetails = ({
  followers,
  createdEvents,
  followings,
  reportedBy,
  blockedBy,
  visits,
}) => {
  const [userFollowers, setUserFollowers] = useState([]);
  const [userfollowings, setUserFollowings] = useState([]);
  const [blockedByList, setBlockedByList] = useState([])

  const [currentFollowerPage, setCurrentFollowerPage] = useState(1)
  const [currentFollowingPage, setCurrentFollowingPage] = useState(1);
  const [currentBlockedPage, setCurrentBlockedPage] = useState(1)

  const [pageSize, setPageSize] = useState(3)
  const [totalFollowings, settotalFollowings] = useState(0)
  const [totalBlockedByList, setTotalBlockedByList] = useState(0)
  const [totalFollowers, setTotalFollowers] = useState(0)

  const [followerModal, setFollowerModal] = useState(false)
  const [followingModal, setFollowingModal] = useState(false)
  const [blockedModal, setBlockedModal] = useState(false)
  const api = useHttp();
  const { id } = useParams()

  console.log(userfollowings);
  const handleChangePage = (page, pageSize) => {

    if (followerModal) {
      setCurrentFollowerPage(page)
    }
    if (followingModal) {
      setCurrentFollowingPage(page)
    }
    if (blockedModal) {
      setCurrentBlockedPage(page)
    }
    setPageSize(pageSize);
  };

  // Blocked By Users List

  useEffect(() => {
    const GET_BLOCKEDBY_API = apiGenerator(CONSTANTS.API.getUserBlockedBy, {}, `?UserId=${id}&page=${currentBlockedPage}&limit=${pageSize}`)
    api.sendRequest(GET_BLOCKEDBY_API, (res) => {
      // console.log(res?.data?.followers);
      setTotalBlockedByList(res?.totalRecords);
      setBlockedByList(res?.data?.blocklist)
      // setUserFollowers(res?.data?.followers);
    })
  }, [currentBlockedPage])


  // Followers Data
  useEffect(() => {
    const GET_FOLLOWERS_API = apiGenerator(CONSTANTS.API.getUserFollowers, {}, `?UserId=${id}&page=${currentFollowerPage}&limit=${pageSize}`)
    api.sendRequest(GET_FOLLOWERS_API, (res) => {
      // console.log(res?.data?.followers);
      setTotalFollowers(res?.totalRecords);
      setUserFollowers(res?.data?.followers);
    })



  }, [currentFollowerPage])

  // Following API
  useEffect(() => {

    const GET_FOLLOWING_API = apiGenerator(CONSTANTS.API.getUserFollowing, {}, `?UserId=${id}&page=${currentFollowingPage}&limit=${pageSize}`)
    api.sendRequest(GET_FOLLOWING_API, (res) => {
      // console.log(res?.data?.followers);
      // settotalFollowings((+res?.totalRecords + +res?.totalFollowingClub));
      settotalFollowings(+res?.totalRecords);
      setUserFollowings(res?.data?.followingClub)
      // setUserFollowings((prev) => {
      //   return [...prev, res?.data?.followings, res?.data?.followingClub]?.flat()
      // })

    })

  }, [currentFollowingPage])
  return (
    <div className="select-text">
      <Row gutter={[16, 16]}>
        <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
          <Card size="small" className="py-2 px-4 cursor-pointer" onClick={() => {
            setFollowerModal(prev => !prev)
          }}>
            <div>
              <p className="font-semibold text-lg text-slate-400">Followers</p>
              <p className=" text-xl font-medium">{followers}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
          <Card size="small" className="py-2 px-4">
            <div>
              <p className="font-semibold  text-lg text-slate-400">
                Events Created
              </p>
              <p className=" text-xl font-medium">{createdEvents}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
          <Card size="small" className="py-2 px-4">
            <div>
              <p className="font-semibold text-lg cursor-pointer text-slate-400" onClick={() => {
                setBlockedModal(prev => !prev)
              }}>Blocked By</p>
              <p className=" text-xl font-medium">{blockedBy}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
          <Card size="small" className="py-2 px-4 cursor-pointer" onClick={() => {
            setFollowingModal(prev => !prev)
          }}>
            <div>
              <p className="font-semibold text-lg text-slate-400">Following</p>
              <p className=" text-xl font-medium">{followings}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
          <Card size="small" className="py-2 px-4">
            <div>
              <p className="font-semibold text-lg text-slate-400">
                Events Attended
              </p>
              <p className=" text-xl font-medium">{visits}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
          <Card size="small" className="py-2 px-4">
            <div>
              <p className="font-semibold text-lg text-slate-400">Reported</p>
              <p className=" text-xl font-medium">{reportedBy}</p>
            </div>
          </Card>
        </Col>
      </Row>

      {/* Followers Modal */}
      <Modal

        open={followerModal}
        onOk={() => setFollowerModal(false)}
        onCancel={() => {
          setFollowerModal(false)
          setCurrentFollowerPage(1)
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}>

        <>
          <Heading>
            Followers List
          </Heading>
          {userFollowers?.length > 0 ? <div>
            {userFollowers?.map(ele => <div key={ele?.id}>
              <Row className="py-2">
                <Col span={4}>
                  <Image
                    className="rounded-full"
                    src={ele?.Following?.profilePic}
                    preview
                    width={40}
                    height={40}
                  />
                </Col>
                <Col span={10}>
                  <p className="p-0 m-0 text-lg">
                    {ele?.Following?.name || "No data Found"}
                  </p>
                  <p className="p-0 m-0 text-xs">
                    {ele?.Following?.userName || "No data Found"}
                  </p>
                </Col>

              </Row>
              <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
            </div>)}

            <Pagination
              current={currentFollowerPage}
              pageSize={pageSize}
              total={totalFollowers}
              onChange={handleChangePage}
              className="mt-10"
            // itemRender={itemRender}
            />
          </div> : <Empty />}
        </>

      </Modal>


      {/* Following Modal */}
      <Modal

        open={followingModal}
        onOk={() => setFollowingModal(false)}
        onCancel={() => {
          setFollowingModal(false)
          setCurrentFollowingPage(1)
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}>

        <>
          <Heading>
            Following List
          </Heading>
          {userfollowings?.length > 0 ? <div>
            {userfollowings?.map(ele => <div key={ele?.id}>
              <Row className="py-2">
                <Col span={4}>
                  <Image
                    className="rounded-full"
                    src={ele?.Followed?.profilePic || ele?.Club?.image}
                    preview
                    width={40}
                    height={40}
                  />
                </Col>
                <Col span={10}>
                  <p className="p-0 m-0 text-lg">
                    {ele?.Followed?.name || ele?.Club?.name || "No data Found"}
                  </p>
                  <p className="p-0 m-0 text-xs">
                    {ele?.Followed?.userName || ele?.Club?.clubUserName || ""}
                  </p>
                </Col>

              </Row>
              <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
            </div>)}

            <Pagination
              current={currentFollowingPage}
              pageSize={pageSize}
              total={totalFollowings}
              onChange={handleChangePage}
              className="mt-10"
            // itemRender={itemRender}
            />
          </div> : <Empty />}
        </>

      </Modal>

      {/* Block List Modal */}
      <Modal

        open={blockedModal}
        onOk={() => setBlockedModal(false)}
        onCancel={() => {
          setBlockedModal(false)
          setCurrentBlockedPage(1)
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}>

        <>
          <Heading>
            Blocked By List
          </Heading>
          {blockedByList?.length > 0 ? <div>
            {blockedByList?.map(ele => <div key={ele?.id}>
              <Row className="py-2">
                <Col span={4}>
                  <Image
                    className="rounded-full"
                    src={ele?.Club?.image || ele?.Manager?.image || ele?.BlockingUser?.profilePic}
                    preview
                    width={40}
                    height={40}
                  />
                </Col>
                <Col span={10}>
                  <p className="p-0 m-0 text-lg">
                    {ele?.Club?.name || ele?.Manager?.name || ele?.BlockingUser?.name || " "}
                  </p>
                  <p className="p-0 m-0 text-xs">
                    {ele?.Club?.clubUserName || ele?.Manager?.userName || ele?.BlockingUser?.userName || " "}
                  </p>
                </Col>

              </Row>
              <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
            </div>)}

            <Pagination
              current={currentBlockedPage}
              pageSize={pageSize}
              total={totalBlockedByList}
              onChange={handleChangePage}
              className="mt-10"
            // itemRender={itemRender}
            />
          </div> : <Empty />}
        </>

      </Modal>

    </div>
  );
};

export default UserProfileDetails;



UserProfileDetails.defaultProps = {
  followers: 'no data',
  createdEvents: 'no data',
  followings: 'no data',
  reportedBy: 'no data',
  blockedBy: 'no data',
  attendedEvents: 'no data',
};
