import React, { useEffect, useState } from "react";
import PageComponent from "../../../../component/common/Page-Component";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import moment from "moment";
import useHttp from "../../../../hooks/use-http";
import { Col, Empty, Image, Modal, Pagination, Row } from "antd";
import Heading from "../../../../component/common/Heading";
import { apiGenerator } from "../../../../util/functions";
import { userVisistsExportData } from "../../../../util/exportData";

const Visits = () => {
  const [likeId, setLikeId] = useState(null)
  const [commentId, setCommentId] = useState(null)
  const [replyId, setReplyId] = useState(null)
  const [likeList, setLikeList] = useState([]);
  const [commentList, setCommentList] = useState([])
  const [replyList, setReplyList] = useState([])
  const [currentLikePage, setCurrentLikePage] = useState(1)
  const [currentCommentPage, setCurrentCommentPage] = useState(1)
  const [currentReplyPage, setCurrentReplyPage] = useState(1)
  const [pageSize, setPageSize] = useState(3)
  const [totalRecords, setTotalRecords] = useState(0)
  const [totalComments, setTotalComments] = useState(0)
  const [totalReplies, setTotalReplies] = useState(0)
  const api = useHttp();
  const { id } = useParams();

  // console.log(likeId);
  // console.log(replyList);

  const handleChangePage = (page, pageSize) => {

    if (likeId !== null) {
      setCurrentLikePage(page)
    }
    if (commentId !== null) {
      setCurrentCommentPage(page)
    }
    if (replyId !== null) {
      setCurrentReplyPage(page)
    }
    setPageSize(pageSize);
  };
  // Like API
  useEffect(() => {
    if (likeId) {
      const GET_LIKE_LIST_API = apiGenerator(CONSTANTS.API.getLikeList, {}, `?VisitLedgerId=${likeId}&page=${currentLikePage}&limit=${pageSize}`)

      api.sendRequest(GET_LIKE_LIST_API, (res) => {
        // console.log(res?.data?.followers);
        setTotalRecords(res?.totalData);
        setLikeList(res?.data?.likes);
      })
    }

  }, [likeId, currentLikePage])

  // Comment API
  useEffect(() => {
    if (commentId) {
      const GET_COMMENT_LIST_API = apiGenerator(CONSTANTS.API.getCommentList, {}, `?VisitLedgerId=${commentId}&page=${currentCommentPage}&limit=${pageSize}`)

      api.sendRequest(GET_COMMENT_LIST_API, (res) => {
        // console.log(res?.data?.followers);
        setTotalComments(res?.totalData);
        setCommentList(res?.data?.comments);
      })
    }


  }, [commentId, currentCommentPage])

  // Reply API
  useEffect(() => {

    if (replyId) {
      const GET_REPLY_LIST_API = apiGenerator(CONSTANTS.API.getReplyList, {}, `?VisitLedgerCommentId=${replyId}&page=${currentReplyPage}&limit=${pageSize}`)

      api.sendRequest(GET_REPLY_LIST_API, (res) => {
        // console.log(res?.data?.followers);
        setTotalReplies(res?.totalData);
        setReplyList(res?.data?.replies);
      })
    }

  }, [replyId])

  return (
    <>
      <div className="mt-5 mx-3">
        <PageComponent
          tableTitle="User Visits List"
          tableHeaders="USERS_POST"
          getAPI={CONSTANTS.API.getVisits}
          extraParams={`&UserId=${id}`}
          extraResData="visitLedgers"
          getData={(res) => {
            // console.log(res);
            return res?.map(data => {
              return {
                ...data,
                no: `V${data?.id.toString().padStart(7, "0")}`,
                clubName: data?.Booking?.Event?.Club?.name,
                date: `${moment(data?.Event?.startDate).format(
                  "DD MMM"
                )}, ${moment
                  .utc(data?.Event?.startDate)
                  .local()
                  .format("hh:mm A")}`,
                likeList: {
                  id: data?.id,
                  onClick: (id) => {
                    setLikeId(id);
                  }
                },
                commentList: {
                  id: data?.id,
                  onClick: (id) => {
                    setCommentId(id);
                  }
                },
              };
            });
          }}
          exportData={userVisistsExportData}
          exportCSV
        />
      </div>

      {/* Like List */}
      <Modal
        open={likeId !== null}
        onOk={() => setLikeId(null)}
        onCancel={() => {
          setLikeId(null)
          setCurrentLikePage(1);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}>

        <Heading>
          Like List
        </Heading>
        {likeList?.length > 0 ? <div>
          {likeList?.map(ele => <div key={ele?.id}>
            <Row className="py-2">
              <Col span={4}>
                <Image
                  className="rounded-full"
                  src={ele?.User?.profilePic || ele?.Manager?.image || ele?.Club?.image}
                  preview
                  width={40}
                  height={40}
                />
              </Col>
              <Col span={10}>
                <p className="p-0 m-0 text-lg">
                  {ele?.User?.name || ele?.Manager?.name || ele?.Club?.name}
                </p>
                <p className="p-0 m-0 text-xs">
                  {ele?.User?.userName || ele?.Manager?.userName || ele?.Club?.userName}
                </p>
              </Col>

            </Row>
            <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
          </div>)}

          <Pagination
            current={currentLikePage}
            pageSize={pageSize}
            total={totalRecords}
            onChange={handleChangePage}
            className="mt-10"
          // itemRender={itemRender}
          />
        </div> : <Empty />}

      </Modal>


      {/* Comment List */}
      <Modal
        open={commentId !== null}
        onOk={() => setCommentId(null)}
        onCancel={() => {
          setCommentId(null);
          setCurrentCommentPage(1)
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}>

        <Heading>
          Comment List
        </Heading>
        {commentList?.length > 0 ? <div> {commentList?.map(ele => <div key={ele?.id}>
          <Row className="py-2">
            <Col span={4}>
              <Image
                className="rounded-full"
                src={ele?.User?.profilePic || ele?.Manager?.image || ele?.Club?.image}
                preview
                width={40}
                height={40}
              />
            </Col>
            <Col span={10}>
              <p className="p-0 m-0 text-lg">
                {ele?.User?.name || ele?.Manager?.name || ele?.Club?.name}
              </p>
              <p className="p-0 m-0 text-xs">
                {ele?.User?.userName || ele?.Manager?.userName || ele?.Club?.userName}
              </p>
            </Col>

          </Row>
          <p className="m-0 p-0 pb-2  text-base">
            <span className="font-bold">Comment :</span> {ele?.comment} <span className="ml-5 select-none text-xs cursor-pointer" onClick={() => { setReplyId(ele?.id) }} style={{ color: '#4C63B6 ' }}>View {ele?.replies} replies</span>
          </p>
          <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
        </div>)}

          <Pagination
            current={currentCommentPage}
            pageSize={pageSize}
            total={totalComments}
            onChange={handleChangePage}
            className="mt-10"
          // itemRender={itemRender}
          /></div> : <Empty />}

      </Modal>

      {/* Reply List */}
      <Modal
        open={replyId !== null}
        onOk={() => setReplyId(null)}
        onCancel={() => {
          setReplyId(null);
          setCurrentReplyPage(1)
          // setCurrentCommentPage(1)
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}>

        <Heading>
          Reply List
        </Heading>
        {replyList?.length > 0 ? <div>
          {replyList?.map(ele => <div key={ele?.id}>
            <Row className="py-2">
              <Col span={4}>
                <Image
                  className="rounded-full"
                  src={ele?.User?.profilePic || ele?.Manager?.image || ele?.Club?.image}
                  preview
                  width={40}
                  height={40}
                />
              </Col>
              <Col span={10}>
                <p className="p-0 m-0 text-lg">
                  {ele?.User?.name || ele?.Manager?.name || ele?.Club?.name}
                </p>
                <p className="p-0 m-0 text-xs">
                  {ele?.User?.userName || ele?.Manager?.userName || ele?.Club?.userName}
                </p>
              </Col>

            </Row>
            <p className="m-0 p-0 pb-2  text-lg">
              <span className="font-bold">Reply: </span> {ele?.reply}
            </p>
            <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
          </div>)}

          <Pagination
            current={currentReplyPage}
            pageSize={pageSize}
            total={totalReplies}
            onChange={handleChangePage}
            className="mt-10"
          // itemRender={itemRender}
          />
        </div> : <Empty />}

      </Modal>


    </>
  );
};

export default Visits;
