import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Popconfirm, Row, Spin, Switch, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import imageSrc from "../../../asset/image/image 2.png";
import panImage from "../../../asset/image/no image.png";
import dayjs from "dayjs";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import AboutInfo from "./tabs/About-info";
import VisitLedger from "./tabs/Visit-ledger";
import Staffs from "./tabs/Staffs";
import Events from "./tabs/Events";
import FloatingIcon from "./tabs/Floating-icon";
import ClubProfile from "./club-profile";
import ClubProfileDetails from "./club-profile-details";
import Post from "./tabs/post";
import moment from "moment";

const ClubDetails = () => {
  const [info, SetInfo] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isUserBlock, setIsUserBlock] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { id } = useParams();
  // console.log(id);
  const api = useHttp();
  // console.log(info);

  const menus = [
    { events: <Events /> },
    { staff: <Staffs /> },
    { ledger: <VisitLedger /> },
    { icon: <FloatingIcon /> },
    { about: <AboutInfo /> },
    { post: <Post /> },
  ];

  useEffect(() => {
    const INFO_API = apiGenerator(CONSTANTS.API.getOneClub, {
      dataId: id,
    });
    api.sendRequest(INFO_API, (res) => {
      // console.log(res?.data?.club);
      SetInfo(prev => {
        return {
          ...prev,
          ...res?.data?.club[0],
          name: res?.data?.club[0]?.name,
          clubUserName: res?.data?.club[0]?.clubUserName,
          bio: res?.data?.club[0]?.bio,
          ownerName: res?.data?.club[0]?.ownerName,
          image: res?.data?.club[0]?.image,
          dob: moment.utc(res?.data?.club[0]?.ownerDOB).local().format('DD MMM, YYYY'),

        }
      });
    });
  }, [refresh]);

  return (
    <>
      {api.isLoading ? (
        <Spin className="mt-16" tip="Loading" size="large">
          <div className="content" />
        </Spin>
      ) : (
        <div>
          <div className="my-5">
            <Row gutter={[16, 16]}>
              <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
                <ClubProfile {...info} />
              </Col>
              <Col span={16} xs={24} sm={24} md={24} lg={16} xl={16}>
                <ClubProfileDetails {...info} />
              </Col>
            </Row>
            <Card className="mt-10">
              <Tabs
                className="p-0 m-0"
                defaultActiveKey="1"
                size="middle"
                type="card"
                items={CONSTANTS.TAB_MENU.CLUB.map((ele) => {
                  return {
                    label: `${ele.Label}`,
                    key: `${ele.id}`,
                    children: menus.map((data) => {
                      if (Object.keys(data)[0] === ele.id) {
                        return data[ele.id];
                      }
                    }),
                  };
                })}
              />
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default ClubDetails;

ClubDetails.defaultProps = {
  image: imageSrc,
  ownerName: "no data",
  clubUserName: "no data",
  name: "no data",
  bio: "no data",
  dob: "no data",
  mobile: "no data",
  email: "no data",
  website: "no data",
  type: "no data",
  address: "no data",
  pincode: "no data",
  landMark: "no data",
  GST: "no data",
  PAN: "no data",
  bankHolder: "no data",
  branch: "no data",
  IFSC: "no data",
  panFile: panImage,
  aadhaarFile: panImage,
};
