import React from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { valetExportData } from "../../../util/exportData";
import moment from "moment";

const Valets = () => {
  return (
    <>
      <PageComponent
        tableHeaders="VALETS"
        tableTitle="Valets List"
        getAPI={CONSTANTS.API.getValets}
        extraResData="valets"
        getData={(res) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `V${data?.id.toString().padStart(7, "0")}`,
              clubName: data?.Club?.name,
              date: moment.utc(data?.createdAt).local().format('DD MMM, YYYY'),
              isBlockedD: data?.isBlocked ? 'Yes' : 'No'
            };
          });
        }}
        editformData
        editModalFields="EDIT_VALETS_MODAL"
        editModalTitle="Edit Valet"
        editAPI={CONSTANTS.API.editValets}
        blockData
        deleteAPI={CONSTANTS.API.deleteValets}
        deleteData
        searchAPI="/valets/search"
        isSearch
        searchfilter
        exportData={valetExportData}
        exportCSV
      />
    </>
  );
};

export default Valets;
