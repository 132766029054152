import React, { useState } from "react";
import { Col, Image, Row } from "antd";
import photo from "../../../../asset/image/clubs.png"
import contact from "../../../../asset/image/dummy-avatar.jpg"
import doc from "../../../../asset/image/docs.svg"
import { useParams } from "react-router-dom";
import { renderDescriptionContent } from "../../../../util/functions";
import moment from "moment";

const SenderChat = ({
    message = "",
    time = "",
    type = '',
    VisitLedger = null,
    Rsvp = null,
    Booking = null,
    Post = null,
}) => {

    const { userId } = useParams()
    const [showMore, setShowMore] = useState(false)
    const invitaion = {
        msg: '',
        status: null,
    }

    if (type === 'RSVP' || type === 'Booking' || type === 'Post') {
        if (message !== '') {
            message = JSON.parse(message)

            if (type === 'RSVP') {
                const rsvpsMsg = message?.rsvpInvitation.filter(ele => +ele?.userId === +userId)


                if (!rsvpsMsg[0]?.accept && !rsvpsMsg[0]?.reject) {
                    invitaion.msg = 'Pending Event'
                    invitaion.status = 0
                }

                else if (rsvpsMsg[0]?.accept) {

                    invitaion.msg = 'You accepted the invitation'
                    invitaion.status = 1

                }
                else {
                    invitaion.msg = 'You declined the invitation'
                    invitaion.status = -1
                }
            }
        }

    }

    // Download Document
    const downloadDoc = () => {
        fetch(message) // Replace 'data.message' with your actual API response field
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${message.split('/')[3]?.trim()}`; // Replace 'file.pdf' with the desired filename
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    }




    return (
        <>
            {type === 'Text' && <div
                style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-4 rounded-lg max-w-lg"
            >
                <p className="text-sm text-left mb-0 pt-3 pb-1">{message}</p>
                <p className="text-slate-600 font-bold -mt-1 text-sm float-left" style={{ fontSize: '10px' }}>{time}</p>
            </div>}

            {type === 'Img' && <div
                style={{ background: "#cbcbcb", color: "black" }}
                className="float-right py-2 px-4 rounded-lg"
            >
                <p className="mx-2 mt-4">
                    <Image src={message || photo} alt="image is not available" height={250} width={250} preview className="block object-cover rounded-lg" />
                </p>
                <p className="text-slate-600 font-bold -mt-1 mb-0 float-left" style={{ fontSize: '10px' }} >{time}</p>
            </div>}

            {type === 'Contact' && <div
                style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-3 py-2 rounded-lg"
            >
                <Row >
                    <Col>
                        <Image src={contact} height={40} preview width={40} alt="contact" />
                    </Col>
                    <Col >
                        <div className="mx-2">
                            <p className="text-sm">
                                {message.split(':')[0]?.trim()}
                            </p>
                            <p className="-mt-4 text-left" style={{ fontSize: '12px' }}>
                                {message.split(':')[1]?.trim()}
                            </p>
                        </div>
                    </Col>
                </Row>
                <p className="text-slate-600  font-bold -mt-1.5 -mb-1  float-left" style={{ fontSize: '10px' }}>{time}</p>
            </div>}
            {type === 'Doc' && <div
                style={{ background: "#cbcbcb", color: "black" }}
                className="cursor-pointer float-right px-4 py-2 rounded-lg"
                onClick={downloadDoc}
            >
                <Row >
                    <Col>
                        <Image src={doc} preview height={40} width={40} alt="document" />
                    </Col>
                    <Col >
                        <div className="mx-2 mt-2">
                            <p className="text-sm">
                                {message.split('/')[3]?.trim()}
                            </p>

                        </div>
                    </Col>
                </Row>
                <p className="text-slate-600  font-bold mt-1 -mb-1  float-left" style={{ fontSize: '10px' }}>{time}</p>
            </div>}
            {type === 'RSVP' && <div style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-4  py-3 rounded-lg">
                <div className="text-white w-80 px-4 py-5" style={{ background: '#1E1E1E', borderRadius: '12px 12px 12px 12px', boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.04)' }}>
                    <p className="p-0 m-0 font-semibold text-left pb-3 text-base">
                        {Rsvp?.Event?.User?.name}  is inviting you to,
                    </p>
                    <div className="py-4 px-3" style={{ borderRadius: '8px', border: '1px solid rgba(255, 255, 255, 0.05)' }}>
                        {/* Club Details */}
                        <div className="my-4">
                            <Row className="py-1 px-2" style={{
                                borderRadius: '16px',
                                background: 'rgba(255, 255, 255, 0.05)'


                            }}>
                                <Col span={6}>
                                    <Image src={Rsvp?.Event?.Club?.image} alt='club' width={50} height={50} className="rounded-full object-cover" />
                                </Col>
                                <Col className="" span={10}>
                                    <p className="m-0 pt-3 text-base">
                                        {Rsvp?.Event?.Club?.name}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        {/* Event Details */}
                        <div>
                            <Row >
                                <Col className="rounded-l-lg" span={7}>
                                    <Image src={Rsvp?.Event?.image} alt='event' className="object-cover rounded-l-lg" width={80} height={80} />
                                </Col>
                                <Col span={17} className="rounded-r-lg" style={{ background: '#3F1200' }} >
                                    <div>
                                        <p className="p-0 m-0 pt-3 ml-2 text-lg">
                                            {Rsvp?.Event?.name}
                                        </p>
                                        <p className="text-slate-400 p-0 m-0 ml-2">
                                            {Rsvp?.Event?.startDate ? moment.utc(Rsvp?.Event?.startDate).local().format('DD MMM, hh:mm A') : ''}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {Rsvp?.status === 1 && <div className="my-2" style={{ background: '#76EE59', borderRadius: '8px' }} >

                            <div className="text-black font-semibold py-2 px-4">
                                You accepted the invitation
                            </div>

                        </div>}
                        {Rsvp?.status === 0 && <div className="my-2" style={{ background: '#EF1A0C', borderRadius: '8px' }} >


                            <div className="text-black font-semibold py-2 px-4">
                                You declined the invitation
                            </div>

                        </div>}
                        {Rsvp?.status === -1 && <div className="my-2 bg-gray-300" style={{ borderRadius: '8px' }} >


                            <div className="text-black font-semibold py-2 px-4">
                                Your invitation is Pending
                            </div>

                        </div>}

                    </div>
                    <p className="text-slate-400 max-w-lg font-bold mt-0.5  float-left" style={{ fontSize: '10px' }}>{time}</p>
                </div>

            </div>}
            {type === 'Booking' && <div style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-4  py-3 rounded-lg">
                <div className="text-white w-80 px-4 py-5" style={{ background: '#1E1E1E', borderRadius: '12px 12px 12px 12px', boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.04)' }}>

                    <p className="p-0 m-0 font-semibold text-left pb-3 text-base">
                        {Booking?.User?.name}&nbsp;booked a event for you, Check the tickets.,
                    </p>
                    <div className="py-4 px-3" style={{ borderRadius: '8px' }}>

                        {/* Event Details */}
                        <div>
                            <Row className="">
                                <Col span={7}>
                                    <Image src={Booking?.Event?.image} alt='event' className="rounded-lg object-cover" width={80} height={100} />
                                </Col>
                                <Col span={17}  >
                                    <div>
                                        <p className="p-0 m-0 pt-3 ml-5 text-left text-lg">
                                            {Booking?.Event?.name}
                                        </p>
                                        <p className="text-white text-opacity-50 p-0 m-0 ml-5 font-semibold text-left">
                                            {Booking?.Event?.startDate ? moment.utc(Booking?.Event?.startDate).local().format('DD MMM, hh:mm A') : ''}
                                        </p>
                                        <Row className="ml-3 pt-2" >
                                            <Col span={6}>
                                                <Image src={Booking?.Event?.Club?.image} alt='club' style={{ border: '1px solid #737373' }} width={30} height={30} className="rounded-full object-cover" />
                                            </Col>
                                            <Col className="" span={14}>
                                                <p className="m-0 pt-1.5 text-sm text-white text-opacity-50">
                                                    {Booking?.Event?.Club?.name}
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>


                    </div>
                    <p className="text-slate-400 max-w-lg font-bold mt-0.5  float-left" style={{ fontSize: '10px' }}>{time}</p>
                </div>

            </div>}
            {type === 'Ledger' && <div style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-4  py-3 rounded-lg">
                <div className="text-white w-80 px-4 py-5" style={{ background: '#1E1E1E', borderRadius: '12px 12px 12px 12px', boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.04)' }}>
                    <div className="py-4 px-3" style={{ borderRadius: '8px', border: '1px solid rgba(255, 255, 255, 0.05)' }}>
                        {/* User Details */}
                        <div className="my-4">
                            <Row className="py-1" style={{


                            }}>
                                <Col span={6}>
                                    <Image src={VisitLedger?.User?.profilePic} alt='club' width={50} height={50} className="rounded-full object-cover" />
                                </Col>
                                <Col className="" span={18}>
                                    <p className="m-0 pt-3 text-base">
                                        @{VisitLedger?.User?.name}&nbsp;
                                        {VisitLedger?.Booking?.Event?.Club && <span className="m-0 pt-3 text-base">
                                            visited at {VisitLedger?.Booking?.Event?.Club?.name}
                                        </span>}
                                    </p>

                                </Col>
                            </Row>
                        </div>
                        {/* Visit Ledger Details */}
                        <div>
                            <div className="flex justify-center items-center">
                                {VisitLedger?.image && <Image src={VisitLedger?.image} width={250}
                                    height={250} className="object-cover rounded-lg" alt="visit ledger" />}
                            </div>
                            <div className="my-1.5 text-sm text-opacity-80 text-left">{renderDescriptionContent(VisitLedger?.experience, showMore)}</div>
                            {VisitLedger?.experience?.split(" ").length > 7 && (
                                <p
                                    className="bg-transparent cursor-pointer border-none -mt-2 float-right"
                                    onClick={() => setShowMore(prev => !prev)}
                                >
                                    {showMore ? " less" : "more"}
                                </p>
                            )}
                        </div>


                    </div>
                    <p className="text-slate-400 max-w-lg font-bold mt-0.5  float-left" style={{ fontSize: '10px' }}>{time}</p>
                </div>

            </div>}
            {type === 'Post' && <div style={{ background: "#cbcbcb", color: "black" }}
                className="float-right px-4  py-3 rounded-lg">
                <div className="text-white w-80 px-4 py-5" style={{ background: '#1E1E1E', borderRadius: '12px 12px 12px 12px', boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.04)' }}>

                    {/* Club Details */}
                    <div className="my-4">
                        <Row className="py-1 px-2" style={{
                            borderRadius: '16px',
                            background: 'rgba(255, 255, 255, 0.05)'


                        }}>
                            <Col span={6}>
                                <Image src={Post?.Club?.image} alt='club' width={50} height={50} className="rounded-full object-cover" />
                            </Col>
                            <Col className="" span={14}>
                                <p className="m-0 pt-3 text-base">
                                    {Post?.Club?.name}&nbsp;<span>{Post?.PostTagUsers?.length > 0 ? (Post?.PostTagUsers?.length > 1) ? `and ${Post?.PostTagUsers?.length} others` : `and ${Post?.PostTagUsers[0]?.User?.name}` : ''}</span>
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="py-4 px-3">

                        {/* Post Image Details */}
                        {(Post?.mediaType === 'Image' && Post?.file) && <div>
                            <div className="flex justify-center items-center">
                                <Image src={Post?.file} className="rounded-lg object-cover" width={250} height={250} alt="post" />
                            </div>
                            <div className="my-1.5 text-opacity-80 text-sm text-left">{renderDescriptionContent(Post?.description, showMore)}</div>
                            {Post?.description?.split(" ").length > 7 && (
                                <p
                                    className="bg-transparent cursor-pointer border-none -mt-2 float-right"
                                    onClick={() => setShowMore(prev => !prev)}
                                >
                                    {showMore ? " less" : "more"}
                                </p>
                            )}
                        </div>}

                        {/* Post Video Details */}
                        {(Post?.mediaType === 'Video' && Post?.file) && <div>
                            <div className="flex justify-center items-center">

                                <Image

                                    alt="post"
                                    preview={{
                                        imageRender: () => (
                                            <video
                                                fileer={Post?.thumbnail}
                                                width="90%"
                                                height='80%'
                                                controls
                                                src={Post?.file}
                                            />
                                        ),
                                        toolbarRender: () => null,
                                    }}
                                    src={Post?.thumbnail}
                                    width={250}
                                    height={250}
                                    className="rounded-lg object-cover"
                                />
                            </div>
                            <div className="my-1.5 text-opacity-80 text-sm text-left">{renderDescriptionContent(Post?.description, showMore)}</div>
                            {Post?.description?.split(" ").length > 7 && (
                                <p
                                    className="bg-transparent cursor-pointer border-none -mt-2 float-right"
                                    onClick={() => setShowMore(prev => !prev)}
                                >
                                    {showMore ? " less" : "more"}
                                </p>
                            )}
                        </div>}


                    </div>
                    <p className="text-slate-400 max-w-lg font-bold mt-0.5  float-left" style={{ fontSize: '10px' }}>{time}</p>
                </div>

            </div>}
        </>
    );
};

export default SenderChat;
