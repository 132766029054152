import React, { useState } from 'react'
import PageComponent from '../../../component/common/Page-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { clubEventExportData } from '../../../util/exportData';
import useHttp from '../../../hooks/use-http';
import { apiGenerator } from '../../../util/functions';

const Events = () => {

    const api = useHttp()
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false)

    const updatePayStatus = (id) => {
        const UPDATE_PAYOUT_STATUS = apiGenerator(CONSTANTS.API.updateEventById, { dataId: id })
        // console.log(id, value);
        api.sendRequest(UPDATE_PAYOUT_STATUS, () => {
            setRefresh(prev => !prev)
        }, {
            payOutStatus: 'processed'
        }, 'Payment Status Update Successfully')
    }


    const filterList = [
        {
            title: "All Events",
            value: "",
        },
        {
            title: "Club Paid Events",
            value: "isPaid=1&type=club",
        },
        {
            title: "Club Unpaid Events",
            value: "isPaid=0&type=club",
        },
        // {
        //     title: "User Paid Events",
        //     value: "isPaid=1&type=user",
        // },
        {
            title: "User Unpaid Events",
            value: "isPaid=0&type=user",
        },
    ];

    const eventDetail = (id) => {
        navigate(`/app/clubs/event/${id}`);
    }
    return (
        <>
            <PageComponent
                tableTitle="Events List"
                tableHeaders="CLUB_EVENTS"
                datefilter
                filterparmas
                filterList={filterList}
                getAPI={CONSTANTS.API.getClubsEvent}
                extraResData='events'
                getData={(res) => {
                    // console.log(res);
                    const currentDate = moment();


                    return res?.map(data => {
                        const startDate = moment.utc(data?.startDate).local().format('YYYY-MM-DD');
                        const endDate = moment.utc(data?.endDate).local().format('YYYY-MM-DD');
                        let status = '';

                        if (moment(startDate)?.isBefore(currentDate) && moment(endDate)?.isAfter(currentDate)) {
                            status = 'OnGoing'
                        }
                        else if (moment(startDate)?.isAfter(currentDate)) {
                            status = 'Upcoming'

                        }
                        else if (moment(endDate)?.isBefore(currentDate)) {
                            status = 'Completed'
                        }

                        return {
                            ...data,
                            no: `E${data?.id.toString().padStart(7, "0")}`,
                            $userName: data?.Club?.name || data?.User?.name,
                            time: data?.startDate ? moment.utc(data?.startDate).local().format("hh:mm A") : '',
                            date: data?.startDate ? moment.utc(data?.startDate).local().format('DD MMM, YYYY') : '',
                            fees: `₹${data?.fees || 0}`,
                            payOutAmount: `₹${data?.payOutAmount || 0}`,
                            total_revenue: `₹${data?.total_revenue || 0}`,
                            isPaid: data?.payOutStatus ? 'Yes' : 'No',
                            paid: {
                                id: data?.id,
                                checked: !data?.payOutStatus ? true : false,
                                onClick: (id) => {
                                    if (!data?.payOutStatus) {

                                        updatePayStatus(id)

                                    }
                                }
                            },
                            event: status,
                        }
                    })
                }}
                viewData
                viewFunction={eventDetail}
                exportData={clubEventExportData}
                exportCSV
                reload={refresh}
            />
        </>
    )
}

export default Events