import React from 'react'
import { useParams } from 'react-router-dom'
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import PageComponent from '../../../../component/common/Page-Component';
import { eventUserExportData } from '../../../../util/exportData';

const UserList = () => {
    const { id } = useParams()

    const EVENT_USER_API = { ...CONSTANTS.API.getParticipateUser }
    EVENT_USER_API.endpoint = `${EVENT_USER_API.endpoint}?EventId=${id}`
    return (
        <>
            <div>
                <PageComponent
                    tableHeaders="EVENTS_USER"
                    tableTitle="Participate User List"
                    getAPI={EVENT_USER_API}
                    extraResData="tickets"
                    getData={(res) => {
                        return res?.map((data) => {

                            return {
                                ...data,
                                no: `U${data?.id.toString().padStart(7, "0")}`,
                                name: data?.User?.name,
                                userName: data?.User?.userName,
                                profilePic: data?.User?.profilePic,
                            };
                        });
                    }}
                    exportData={eventUserExportData}
                    exportCSV
                />
            </div>
        </>
    )
}

export default UserList