import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, DatePicker, Row } from "antd";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import dayjs from "dayjs";
const Dashboard = () => {
  const api = useHttp();

  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null
  })
  const [analyticsData, setAnalyticsData] = useState({})
  const { RangePicker } = DatePicker;

  const navigate = useNavigate();


  useEffect(() => {
    const ANALYTICS_API = { ...CONSTANTS.API.getAnalytics }
    if ((selectedDates?.startDate !== null && selectedDates?.endDate !== null)) {
      ANALYTICS_API.endpoint = `${ANALYTICS_API.endpoint}?startDate=${selectedDates?.startDate}&endDate=${selectedDates?.endDate}`
    }
    api.sendRequest(ANALYTICS_API, (res) => {
      setAnalyticsData(res?.data?.counts);

    });
  }, [selectedDates]);
  return (

    <div className="">
      <div className="my-5">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card>
              <p className="font-medium pb-5">Date Range</p>
              <RangePicker
                className="w-3/4"
                onChange={(e) => {
                  if (e) {
                    setSelectedDates({
                      startDate: dayjs(e[0])?.format("YYYY-MM-DD"),
                      endDate: dayjs(e[1])?.format("YYYY-MM-DD"),
                    });
                  }
                  else {
                    setSelectedDates({
                      startDate: null,
                      endDate: null,
                    });
                  }
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <Row gutter={[16, 16]}>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className="cursor-pointer" onClick={() => {
          navigate(`/app/users`);
        }}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-base text-slate-400">Total User</p>
              <p className="pt-2 font-medium text-xl ">{analyticsData?.users}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className="cursor-pointer" onClick={() => {
          navigate(`/app/clubs`);
        }}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-base text-slate-400">Total Club</p>
              <p className="pt-2 font-medium text-xl ">{analyticsData?.clubs}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className="cursor-pointer" onClick={() => {
          navigate(`/app/bouncers`);
        }}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-base text-slate-400">Total Bouncer</p>
              <p className="pt-2 font-medium text-xl ">{analyticsData?.bouncers}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className="cursor-pointer" onClick={() => {
          navigate(`/app/valets`);
        }}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-base text-slate-400">Total Valet</p>
              <p className="pt-2 font-medium text-xl ">{analyticsData?.valets}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className="cursor-pointer" onClick={() => {
          navigate(`/app/managers`);
        }}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-base text-slate-400">Total Manager</p>
              <p className="pt-2 font-medium text-xl ">{analyticsData?.managers}</p>
            </div>
          </Card>
        </Col>
        <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8} className="cursor-pointer" onClick={() => {
          navigate(`/app/events`);
        }}>
          <Card size="small" className="p-5">
            <div>
              <p className="font-semibold text-base text-slate-400">Total Event</p>
              <p className="pt-2 font-medium text-xl ">{analyticsData?.events}</p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;


