import React, { useState } from 'react'
import PageComponent from '../../../../component/common/Page-Component'
import CONSTANTS from '../../../../util/constant/CONSTANTS'
import { userReportExportData } from '../../../../util/exportData'
import { Col, Image, Modal, Row } from 'antd'
import profile from "../../../../asset/image/image 2.png";
import useHttp from '../../../../hooks/use-http'

const User = () => {
    const [reportData, setReportData] = useState(null)
    const [refresh, setRefresh] = useState(false)
    // console.log(reportData);
    const api = useHttp()
    const blockUser = (id, checked) => {
        const USER_BLOCK_API = { ...CONSTANTS.API.editUser }
        USER_BLOCK_API.endpoint = USER_BLOCK_API.endpoint.replace(':dataId', id)
        const payload = {
            isBlocked: !checked
        }
        api.sendRequest(USER_BLOCK_API, () => {
            setRefresh(prev => !prev);
        }, payload)
    }
    return (
        <>
            <div className="mt-5 mx-3">
                <PageComponent
                    tableHeaders="USER_REPORT"
                    tableTitle="User Report"
                    getAPI={CONSTANTS.API.getUserReports}
                    extraResData="reports"
                    getData={(res) => {
                        return res?.map(data => {
                            return {
                                ...data,
                                no: `UR${data?.id.toString().padStart(7, "0")}`,
                                reportedUser: data?.ReportedUser?.name,
                                reportedUserProfile: data?.ReportedUser?.profilePic,
                                reportingUser: data?.ReportingUser?.name,
                                reportingUserProfile: data?.ReportingUser?.profilePic,
                                viewData: {
                                    id: data?.id,
                                    onClick: () => {
                                        setReportData(data)
                                    }
                                },
                                block: {
                                    id: data?.ReportedUser?.id,
                                    checked: data?.ReportedUser?.isBlocked,
                                    onClick: blockUser
                                },
                                isBlockedD: data?.ReportedUser?.isBlocked ? 'Block' : 'Un-block'

                            }
                        })
                    }}
                    blockData
                    // editAPI={}
                    reload={refresh}
                    datefilter
                    exportData={userReportExportData}
                    exportCSV
                />
            </div>
            <Modal
                // title="Vertically centered modal dialog"
                centered
                open={reportData !== null}
                onOk={() => setReportData(null)}
                onCancel={() => setReportData(null)}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
            >
                <div className="flex">
                    <Image
                        className="rounded-full"
                        src={reportData?.ReportedUser?.profilePic || profile}
                        preview
                        width={50}
                        height={50}
                    />
                    <h2 className="mt-1 pl-3">{reportData?.ReportedUser?.name}</h2>
                </div>
                <section className="mt-5">
                    <p className="text-slate-500 font-medium">{reportData?.reason}</p>

                </section>
                <section>
                    <h3>Reported By</h3>
                    {/* Headers */}

                    <Row>
                        <Col span={4}>
                            <Image
                                className="rounded-full"
                                src={reportData?.ReportingUser?.profilePic || profile}
                                preview
                                width={40}
                                height={40}
                            />
                        </Col>
                        <Col span={8}>
                            <p className="text-xl mt-2">{reportData?.ReportingUser?.name}</p>
                        </Col>
                        {/* <Col span={11}>
                            <p className="float-right  text-white px-4 py-1 rounded-lg text-xl mt-2" style={{ background: 'var(--greadient, linear-gradient(90deg, #FE8C00 0%, #F83600 100%))' }} >reportData</p>
                        </Col> */}

                    </Row>
                    <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
                    {/* Data */}
                    {/* {reportData?.map((ele) => {
                        return (
                            <div key={ele?.id}>
                                <Row>
                                    <Col span={4}>
                                        <Image
                                            className="rounded-full"
                                            src={ele.image || profile}
                                            preview
                                            width={40}
                                            height={40}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <p className="text-xl mt-2">
                                            {ele?.name}
                                        </p>
                                    </Col>
                                </Row>
                                <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
                            </div>
                        );
                    })} */}
                </section>
            </Modal>

        </>
    )
}

export default User