import React from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { userExportData } from "../../../util/exportData";


const Incompleted = () => {
    const navigate = useNavigate();

    const userDetail = (id) => {
        navigate(`/app/users/${id}`);
    };
    return (
        <>
            <PageComponent
                tableHeaders="USERS"
                tableTitle="Users List"
                getAPI={CONSTANTS.API.getIncompleteUsers}
                extraResData="users"
                getData={(res) => {
                    // console.log(res);
                    return res?.map((data) => {
                        return {
                            ...data,
                            no: `U${data?.id.toString().padStart(7, "0")}`,
                            dob: moment(data?.dob).format("DD MM, YYYY"),
                            privateAccD: data?.privateAcc ? 'Yes' : 'No',
                            isBlockedD: data?.isBlocked ? 'Yes' : 'No'
                        };
                    });
                }}

                viewData
                viewFunction={userDetail}
                blockData
                editAPI={CONSTANTS.API.editUser}
                deleteData
                deleteAPI={CONSTANTS.API.deleteUsers}
                checkboxData
                exportData={userExportData}
                exportCSV
            />
        </>
    );
};

export default Incompleted;
