import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, InputNumber, Row, Switch } from "antd";
import Heading from "../../../component/common/Heading";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";

const Setting = () => {
  const [appConfigureation, setAppConfigureation] = useState({
    isUserAppInMaintanance: null,
    isBusinessAppInMaintanance: null,
    isWorkerAppInMaintanance: null,
    userAppSoftUpdate: null,
    businessAppSoftUpdate: null,
    workerAppSoftUpdate: null,
    userAppForceUpdate: null,
    businessAppForceUpdate: null,
    workerAppForceUpdate: null,
    convenienceFee: 0,
    userAppAndroidVersion: null,
    businessAppAndroidVersion: null,
    workerAppAndroidVersion: null,
    userAppIosVersion: null,
    businessAppIosVersion: null,
    workerAppIosVersion: null,
    userReferrals: null,
    paidPostPrice: null,
    paidPostDays: null,
  });
  // console.log(appConfigureation);
  const api = useHttp()


  const submitHandler = (value) => {
    const payload = {
      isUserAppInMaintanance: value?.isUserAppInMaintanance,
      isBusinessAppInMaintanance: value?.isBusinessAppInMaintanance,
      isWorkerAppInMaintanance: value?.isWorkerAppInMaintanance,
      userAppSoftUpdate: value?.userAppSoftUpdate,
      businessAppSoftUpdate: value?.businessAppSoftUpdate,
      workerAppSoftUpdate: value?.workerAppSoftUpdate,
      userAppForceUpdate: value?.userAppForceUpdate,
      businessAppForceUpdate: value?.businessAppForceUpdate,
      workerAppForceUpdate: value?.workerAppForceUpdate,
      convenienceFee: value?.convenienceFee,
      userAppAndroidVersion: value?.userAppAndroidVersion,
      businessAppAndroidVersion: value?.businessAppAndroidVersion,
      workerAppAndroidVersion: value?.workerAppAndroidVersion,
      userAppIosVersion: value?.userAppIosVersion,
      businessAppIosVersion: value?.businessAppIosVersion,
      workerAppIosVersion: value?.workerAppIosVersion,
      userReferrals: value?.userReferrals,
      paidPostPrice: value?.paidPostPrice,
      paidPostDays: value?.paidPostDays,
    }
    // console.log(payload);


    const SET_APPCONFIG_API = { ...CONSTANTS.API.setAppConfig }
    api.sendRequest(SET_APPCONFIG_API, () => { }, payload, 'Configuration Upadated Successfully!!!')
  }

  useEffect(() => {
    const GET_APPCONFIG_API = { ...CONSTANTS.API.getAppConfig }
    api.sendRequest(GET_APPCONFIG_API, (res) => {
      setAppConfigureation(res?.data?.appConfig);

    })
  }, [])
  const [form] = Form.useForm();
  form.setFieldsValue(appConfigureation)
  return <>
    <Card className="my-5 select-text">
      <Form onFinish={submitHandler} form={form}>
        <Row>
          <Col span={12}> <Heading>
            App Config
          </Heading>
            <Row>
              <Col span={12}>
                <p className="text-xl mt-1">
                  Convenience Fee
                </p></Col>
              <Col>
                <Form.Item name='convenienceFee' id="convenienceFee"  >
                  <InputNumber value={appConfigureation?.convenienceFee} onChange={(value) => {
                    // console.log(e);
                    setAppConfigureation(prev => {
                      return {
                        ...prev,
                        convenienceFee: value
                      }
                    })
                  }} />
                </Form.Item>
              </Col>
            </Row>
            {/* Android & IOSVersion */}
            <Row>
              <Col span={12}>
                <p className="text-xl mt-1">
                  User App Android Version
                </p></Col>
              <Col>
                <Form.Item name='userAppAndroidVersion' id="userAppAndroidVersion"  >
                  <Input type="text" onChange={(e) => {
                    // console.log(+e.target.value);
                    setAppConfigureation(prev => {
                      return {
                        ...prev,
                        userAppAndroidVersion: e.target.value
                      }
                    })
                  }} />

                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p className="text-xl mt-1">
                  User App IOS Version
                </p></Col>
              <Col>
                <Form.Item name='userAppIosVersion' id="userAppIosVersion"  >
                  <Input type="text" onChange={(e) => {
                    // console.log(+e.target.value);
                    setAppConfigureation(prev => {
                      return {
                        ...prev,
                        userAppIosVersion: e.target.value
                      }
                    })
                  }} />

                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <p className="text-xl mt-1">
                  Business App Android Version
                </p></Col>
              <Col>
                <Form.Item name='businessAppAndroidVersion' id="businessAppAndroidVersion"  >
                  <Input type="text" onChange={(e) => {
                    // console.log(+e.target.value);
                    setAppConfigureation(prev => {
                      return {
                        ...prev,
                        businessAppAndroidVersion: e.target.value
                      }
                    })
                  }} />

                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p className="text-xl mt-1">
                  Business App IOS Version
                </p></Col>
              <Col>
                <Form.Item name='businessAppIosVersion' id="businessAppIosVersion"  >
                  <Input type="text" onChange={(e) => {
                    // console.log(+e.target.value);
                    setAppConfigureation(prev => {
                      return {
                        ...prev,
                        businessAppIosVersion: e.target.value
                      }
                    })
                  }} />

                </Form.Item>
              </Col>
            </Row>


            <Row>
              <Col span={12}>
                <p className="text-xl mt-1">
                  Worker App Android Version
                </p></Col>
              <Col>
                <Form.Item name='workerAppAndroidVersion' id="workerAppAndroidVersion"  >
                  <Input type="text" onChange={(e) => {
                    // console.log(+e.target.value);
                    setAppConfigureation(prev => {
                      return {
                        ...prev,
                        workerAppAndroidVersion: e.target.value
                      }
                    })
                  }} />

                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p className="text-xl mt-1">
                  Worker App IOS Version
                </p></Col>
              <Col>
                <Form.Item name='workerAppIosVersion' id="workerAppIosVersion"  >
                  <Input type="text" onChange={(e) => {
                    // console.log(+e.target.value);
                    setAppConfigureation(prev => {
                      return {
                        ...prev,
                        workerAppIosVersion: e.target.value
                      }
                    })
                  }} />

                </Form.Item>
              </Col>
            </Row>

            {/* User Referrals */}
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  User Referrals
                </p></Col>
              <Col>
                <Form.Item name='userReferrals' id="userReferrals" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.userReferrals}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          userReferrals: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Maintanance */}
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  User App Under Maintanance
                </p></Col>
              <Col>
                <Form.Item name='isUserAppInMaintanance' id="isUserAppInMaintanance" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.isUserAppInMaintanance}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          isUserAppInMaintanance: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  Business App Under Maintanance
                </p></Col>
              <Col>
                <Form.Item name='isBusinessAppInMaintanance' id="isBusinessAppInMaintanance" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.isBusinessAppInMaintanance}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          isBusinessAppInMaintanance: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  Worker App Under Maintanance
                </p></Col>
              <Col>
                <Form.Item name='isWorkerAppInMaintanance' id="isWorkerAppInMaintanance" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.isWorkerAppInMaintanance}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          isWorkerAppInMaintanance: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Soft Update */}
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  User App Soft Update
                </p></Col>
              <Col>
                <Form.Item name='userAppSoftUpdate' id="userAppSoftUpdate" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.userAppSoftUpdate}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          userAppSoftUpdate: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  Business App Soft Update
                </p></Col>
              <Col>
                <Form.Item name='businessAppSoftUpdate' id="businessAppSoftUpdate" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.businessAppSoftUpdate}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          businessAppSoftUpdate: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  Worker App Soft Update
                </p></Col>
              <Col>
                <Form.Item name='workerAppSoftUpdate' id="workerAppSoftUpdate" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.workerAppSoftUpdate}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          workerAppSoftUpdate: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Force Update */}
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  User App Force Update
                </p></Col>
              <Col>
                <Form.Item name='userAppForceUpdate' id="userAppForceUpdate" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.userAppForceUpdate}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          userAppForceUpdate: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  Business App Force Update
                </p></Col>
              <Col>
                <Form.Item name='businessAppForceUpdate' id="businessAppForceUpdate" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.businessAppForceUpdate}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          businessAppForceUpdate: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={13}>
                <p className="text-xl mt-1">
                  Worker App Force Update
                </p></Col>
              <Col>
                <Form.Item name='workerAppForceUpdate' id="workerAppForceUpdate" >
                  <Switch
                    className="mt-2"
                    checked={appConfigureation.workerAppForceUpdate}
                    onChange={(value) => {
                      setAppConfigureation(prev => {
                        return {
                          ...prev,
                          workerAppForceUpdate: value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

          </Col>
          <Col span={12} > <Heading>
            Marketed Post
          </Heading>
            <Row>
              <Col span={3}>
                <p className="text-xl mt-0">
                  Price
                </p></Col>
              <Col>
                <Form.Item name='paidPostPrice' id="paidPostPrice"  >

                  <InputNumber value={appConfigureation?.paidPostPrice} onChange={(value) => {
                    // console.log(e);
                    setAppConfigureation(prev => {
                      return {
                        ...prev,
                        paidPostPrice: value
                      }
                    })
                  }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <p className="text-xl mt-0">
                  Days
                </p></Col>
              <Col>
                <Form.Item name='paidPostDays' id="paidPostDays"  >

                  <InputNumber value={appConfigureation?.paidPostDays} onChange={(value) => {
                    // console.log(e);
                    setAppConfigureation(prev => {
                      return {
                        ...prev,
                        paidPostDays: value
                      }
                    })
                  }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>




        <p>
          Last Updated on {moment.utc(appConfigureation?.updatedAt).local().format('DD MMM, YYYY')} {moment.utc(appConfigureation?.updatedAt).local().format('hh:mm A')}
        </p>

        <Form.Item

        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

    </Card>

  </>;
};

export default Setting;
