import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import useHttp from "../../../../hooks/use-http";
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Card, Col, Image, Row, Space } from "antd";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import panImage from "../../../../asset/image/no image.png";
import imageSrc from "../../../../asset/image/image 2.png";

const AboutInfo = ({
  image,
  ownerName,
  clubUserName,
  name,
  bio,
  dob,
  mobile,
  email,
  website,
  type,
  address,
  pinCode,
  landMark,
  GST,
  PAN,
  bankHolder,
  branch,
  IFSC,
  panFile,
  aadhaarFile,
  fssaiCertificate,
}) => {
  const [info, SetInfo] = useState({});
  const { id } = useParams()
  const api = useHttp();


  const onDownload = (src) => {

    // console.log(src);

    window.open(src, '_blank')

    // const anchor = document.createElement('a');

    // // Set the URL you want to open in a new tab
    // anchor.href = src; // Replace with your desired URL

    // // Set the download attribute to specify the filename
    // anchor.download = 'pancar.png'; // Replace with your desired filename

    // // Simulate a click event to open the link in a new tab
    // anchor.target = '_blank'; // Open in a new tab
    // document.body.appendChild(anchor);
    // anchor.click();
    // document.body.removeChild(anchor);



  };


  useEffect(() => {
    const CLUB_DETAILS_API = apiGenerator(CONSTANTS.API.getOneClub, { dataId: id })
    api.sendRequest(CLUB_DETAILS_API, (res) => {
      SetInfo({
        ownerName: res?.data?.club[0]?.ownerName,
        dob: res?.data?.club[0]?.ownerDOB ? moment.utc(res?.data?.club[0]?.ownerDOB).local().format('DD MMM, YYYY') : '',
        mobile: res?.data?.club[0]?.mobile,
        email: res?.data?.club[0]?.email,
        website: res?.data?.club[0]?.website,
        type: res?.data?.club[0]?.type,
        address: res?.data?.club[0]?.address,
        pinCode: res?.data?.club[0]?.pinCode,
        landMark: res?.data?.club[0]?.landMark,
        GST: res?.data?.club[0]?.GST,
        PAN: res?.data?.club[0]?.PAN,
        bancAccHolderName: res?.data?.club[0]?.bancAccHolderName,
        branch: res?.data?.club[0]?.bancBranch,
        IFSC: res?.data?.club[0]?.IFSC,
        panFile: res?.data?.club[0]?.panFile,
        aadhaarFile: res?.data?.club[0]?.aadhaarFile,
        fssaiCertificate: res?.data?.club[0]?.fssaiCertificate,
        lat: res?.data?.club[0]?.lat,
        lng: res?.data?.club[0]?.lng,
        lng: res?.data?.club[0]?.lng,
        bancAccNo: res?.data?.club[0]?.bancAccNo,
      });
    })
  }, [])
  return (
    <>
      <div className="select-text">
        <Card>
          <Row>
            <Col span={16}>
              <p className="font-bold text-2xl">About Info</p>
              <div className="mt-3 flex ml-5">
                <p className="font-semibold text-slate-400 text-base">Owner</p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.ownerName || ownerName}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  Mobile Number
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.mobile || mobile}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  Date of birth
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.dob || dob}
                </p>
              </div>

              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">Email</p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.email || email}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  Website
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.website || website}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">Type</p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.type || type}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  Address
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.address || address}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  Pin Code
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.pinCode || pinCode}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  Landmark
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.landMark || landMark}
                </p>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  GST
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.GST || GST}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  PAN
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.PAN || PAN}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  Bank Account Holder Name
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.bancAccHolderName || bankHolder}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  Bank Account Number
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.bancAccNo || ''}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  Branch Bank Account
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.branch || branch}
                </p>
              </div>
              <div className="flex ml-5">
                <p className="font-semibold text-slate-400 text-base">
                  IFSC Code
                </p>
                <p className="font-bold text-base">
                  &nbsp;: {info?.IFSC || IFSC}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="my-5">
            <Col span={8}>
              <h2 className="font-bold">Pan Card</h2>
              <Image
                src={info?.panFile || panFile}
                width={300}
                height={200}
                className="rounded-2xl"
                preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      <DownloadOutlined onClick={() => {
                        onDownload(info?.panFile)
                      }} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                  ),
                }}


              />
            </Col>
            <Col span={8}>
              <h2 className="font-bold">Aadhar Card</h2>
              <Image
                src={info?.aadhaarFile || aadhaarFile}
                width={300}
                height={200}
                className="rounded-2xl"
                preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      <DownloadOutlined onClick={() => {
                        onDownload(info?.aadhaarFile)
                      }} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                  ),
                }}
              />
            </Col>
            <Col span={5}>
              <h2 className="font-bold">Fssai Proof</h2>
              <Image
                src={info?.fssaiCertificate || fssaiCertificate}
                width={300}
                height={200}
                className="rounded-2xl"
                preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      <DownloadOutlined onClick={() => {
                        onDownload(info?.fssaiCertificate)
                      }} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                  ),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <h2 className="font-bold">Location</h2>
              <iframe
                width={300}
                height={200}
                src={`https://maps.google.com/?q=${info?.lat || `21.240347`},${info?.lng || `72.886982`}&ie=UTF8&iwloc=&output=embed`}
              ></iframe>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default AboutInfo;

AboutInfo.defaultProps = {
  image: imageSrc,
  ownerName: "",
  clubUserName: "",
  name: "",
  bio: "",
  dob: "",
  mobile: "",
  email: "",
  website: "",
  type: "",
  address: "",
  pincode: "",
  landMark: "",
  GST: "",
  PAN: "",
  bankHolder: "",
  branch: "",
  IFSC: "",
  panFile: panImage,
  aadhaarFile: panImage,
  fssaiCertificate: panImage,
};
