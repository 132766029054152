import React from 'react'
import PageComponent from '../../../component/common/Page-Component';
import { managerExportData } from '../../../util/exportData';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';

const Manager = () => {
    return (
        <div>
            <PageComponent
                tableHeaders="MANAGER"
                tableTitle="Manager List"
                getAPI={CONSTANTS.API.getManager}
                extraResData="managers"
                getData={(res) => {
                    return res?.map((data) => {
                        return {
                            ...data,
                            no: `V${data?.id.toString().padStart(7, "0")}`,
                            clubName: data?.Club?.name,
                            date: moment.utc(data?.createdAt).local().format('DD MMM, YYYY'),
                            isBlockedD: data?.isBlocked ? 'Yes' : 'No'
                        };
                    });
                }}

                searchAPI="/managers/search"
                isSearch
                searchfilter
                // editformData
                // editModalFields="EDIT_MANAGER_MODAL"
                // editModalTitle="Edit Manager"
                editAPI={CONSTANTS.API.editManager}
                blockData
                // deleteAPI={CONSTANTS.API.deleteManager}
                // deleteData
                exportData={managerExportData}
                exportCSV
            />

        </div>
    )
}

export default Manager