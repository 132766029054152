import moment from "moment";
import React from "react";

const AboutInfo = ({ dob, userName, mobile, bio, gender, createdAt, myReferralCode }) => {
  return (
    <div className="select-text">
      <div className="mt-3 flex ml-5">
        <p className="font-semibold text-slate-400 text-lg">Username</p>
        <p className="font-bold text-lg">&nbsp;: {userName}</p>
      </div>
      <div className="flex ml-5">
        <p className="font-semibold text-slate-400 text-lg">Bio</p>
        <p className="font-bold text-lg">
          &nbsp;: {bio}
        </p>
      </div>
      <div className="flex ml-5">
        <p className="font-semibold text-slate-400 text-lg">Mobile Number</p>
        <p className="font-bold text-lg">&nbsp;: {mobile}</p>
      </div>

      <div className="flex ml-5">
        <p className="font-semibold text-slate-400 text-lg">Date of birth</p>
        <p className="font-bold text-lg">
          &nbsp;: {dob ? moment.utc(dob).local().format("DD MMM, YYYY") : ''}
        </p>
      </div>
      <div className="flex ml-5">
        <p className="font-semibold text-slate-400 text-lg">Gender</p>
        <p className="font-bold text-lg">
          &nbsp;: {gender}
        </p>
      </div>
      <div className="flex ml-5">
        <p className="font-semibold text-slate-400 text-lg">Joining Date</p>
        <p className="font-bold text-lg">
          &nbsp;: {moment.utc(createdAt).local().format("DD MMM, YYYY")}
        </p>
      </div>
      <div className="flex ml-5">
        <p className="font-semibold text-slate-400 text-lg">Referral Code</p>
        <p className="font-bold text-lg">
          &nbsp;: {myReferralCode}
        </p>
      </div>
    </div>
  );
};

export default AboutInfo;

AboutInfo.defaultProps = {
  userName: 'no data',
  dob: 'no data',
  mobile: 'no data',
  bio: 'no data',
  gender: 'no data',
  createdAt: 'no data',
  myReferralCode: 'no data',
};
