import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ALL_ROUTES from "./util/Route";
function App() {


  const router = createBrowserRouter(ALL_ROUTES);
  return <RouterProvider router={router} />;
}

export default App;
