export const userExportData = [
    ["no", "User Id"],
    ["profilePic", "Profile"],
    ["name", "Name"],
    ["userName", "User Name"],
    ["mobile", "Mobile Number"],
    ["privateAccD", "Private account"],
    ["isBlockedD", "Block User"],

];

export const waitUserExportData = [
    ["no", "User Id"],
    ["mobile", "Mobile Number"],
    ["date", "Signup Date"],

];
export const clubExportData = [
    ["no", "Club Id"],
    ["image", "Profile"],
    ["name", "Club Name"],
    ["ownerName", "Owner Name"],
    ["mobile", "Mobile Number"],

];
export const singleClubExportData = [
    ["name", "Club Name"],
    ["clubUserName", "Club UserName"],
    ["ownerName", "Owner Name"],
    ["bio", "Bio"],
    ["mobile", "Mobile Number"],
    ["dob", "Date of Birth"],
    ["email", "Email"],
    ["website", "Website"],
    ["image", "Club Image"],
    ["type", "Club Type"],
    ["pinCode", "Pincode"],
    ["landMark", "Landmark"],
    ["GST", "GST Number"],
    ["PAN", "PAN Number"],
    ["aadhaarFile", "Aadhar File"],
    ["panFile", "PAN File"],
    ["fssaiCertificate", "Fassai File"],
    ["bancAccHolderName", "Bank Account Holder Name"],
    ["bancBranch", "Bank Branch"],
    ["IFSC", "IFSC Code"],
    ["followers", "Followers"],
    ["events", "Total Events"],
    ["totalVisits", "Total Visits "],
    ["acceptedEvents", "Accepted Events "],
    ["pendingEvents", "Pending Events "],
    ["rejectedEvents", "Rejected Events "],

];
export const requestExportData = [
    ["no", "Request Id"],
    ["image", "Profile"],
    ["name", "Club Name"],
    ["ownerName", "Owner Name"],
    ["mobile", "Mobile Number"],

];

export const userValetExportData = [
    ["no", "Visit Id"],
    ["image", "Image"],
    ["name", "Event"],
    ["userName", "User Name"],
    ["date", "Date"],
    ["time", "Time"],
    ["experience", "Description"],

]

export const bouncerExportData = [
    ["no", "Bouncer Id"],
    ["image", "Profile"],
    ["name", "Bouncer Name"],
    ["clubName", "Club Name"],
    ["mobile", "Mobile Number"],
    ["date", "Date"],
    ["isBlockedD", "Block Bouncer"],

];
export const managerExportData = [
    ["no", "Manager Id"],
    ["image", "Profile"],
    ["name", "Manager Name"],
    ["clubName", "Club Name"],
    ["mobile", "Mobile Number"],
    ["isBlockedD", "Block Manager"],

];
export const valetExportData = [
    ["no", "Valet Id"],
    ["image", "Profile"],
    ["name", "Valet Name"],
    ["clubName", "Club Name"],
    ["mobile", "Mobile Number"],
    ["isBlockedD", "Block Valet"],

];

export const foodCategoryExportData = [
    ["no", "Food Category Id"],
    ["category", "Food Category Name"],

];

export const clubEventExportData = [
    ["no", "Event Id"],
    ["image", "Profile"],
    ["name", "Event Name"],
    ["userName", "User Name"],
    ["date", "Date"],
    ["time", "Time"],
    ["fees", "Fees"],
    ["isPaid", "isPaid"],
    ["payOutAmount", "Paid Amount"],
    ["total_revenue", "Revenue"],
    ["event", "Event"],

];
export const clubManagerExportData = [
    ["no", "Manager Id"],
    ["image", "Profile"],
    ["name", "Name"],
    ["clubName", "Club Name"],
    ["mobile", "Mobile Number"],
    ["staffs", "Staffs"],
    ["date", "Account Created Date"],

];
export const clubBouncerExportData = [
    ["no", "Bouncer Id"],
    ["image", "Profile"],
    ["name", "Name"],
    ["clubName", "Club Name"],
    ["mobile", "Mobile Number"],
    ["staffs", "Staffs"],
    ["date", "Account Created Date"],

];
export const clubValetExportData = [
    ["no", "Valet Id"],
    ["image", "Profile"],
    ["name", "Name"],
    ["clubName", "Club Name"],
    ["mobile", "Mobile Number"],
    ["staffs", "Staffs"],
    ["date", "Account Created Date"],

];
export const clubVisitLedgerExportData = [
    ["no", "Visit Ledger Id"],
    ["image", "Profile"],
    ["name", "Event"],
    ["mobile", "Date"],
    ["staffs", "Time"],
    ["experience", "Description"],

];
export const clubPostExportData = [
    ["no", "Post Id"],
    ["file", "Post"],
    ["description", "Description"],
    ["createdAt", "Created At"],

];
export const clubMenuExportData = [
    ["no", "Menu Id"],
    ["image", "Image"],
    ["name", "Food Name"],
    ["price", "Price"],
    ["discount", "Discount"],
    ["description", "Description"],

];
export const clubBannerExportData = [
    ["no", "Banner Id"],
    ["image", "Banner"],
    ["dimensions", "Dimensions"],
    ["link", "Navigation Link"],

];
export const clubCarValetExportData = [
    ["no", "Car Valet Id"],
    ["userName", "User Name"],
    ["keyNumber", "Key Number"],
    ["status", "Status"],
    ["id", "Request Id"],
    ["dateTime", "Date & Time"],

];
export const userCarValetExportData = [
    ["no", "Car Valet Id"],
    ["name", "Club Name"],
    ["keyNumber", "Key Number"],
    ["status", "Status"],
    ["id", "Request Id"],
    ["dateTime", "Date & Time"],

];
export const userVisistsExportData = [
    ["no", "Car Valet Id"],
    ["image", "Post"],
    ["date", "Date & Time"],
    ["likeCount", "Likes"],
    ["commentCount", "Comment"],
    ["experience", "Caption"],

];
export const userChatHistoryExportData = [
    ["no", "Chat Id"],
    ["image", "Image"],
    ["name", "Name"],
    ["type", "Type"],
    ["date", "Date & Time"],

];
export const userBookingExportData = [
    ["no", "Booking Id"],
    ["image", "Image"],
    ["club", "Club Name"],
    ["eventName", "Event Name"],
    ["date", "Date & Time"],
    ["entries", "Entries"],
    ["total", "Total Amount"],

];
export const postExportData = [
    ["no", "Post Report Id"],
    ["name", "Club Name"],
    ["userName", "User Name"],
    ["image", "Reporting Post"],
    ["isBlockedD", "Reported Post Block"],

];
export const userReportExportData = [
    ["no", "User Report Id"],
    ["reportedUser", "Reported User "],
    ["reportedUserProfile", "Reported User Profile"],
    ["reportingUser", "Reporting User"],
    ["reportingUserProfile", "Reporting User Profile"],
    ["isBlockedD", "Reported User Block"],

];
export const visitReportExportData = [
    ["no", "Visit Report Id"],
    ["image", "Profile"],
    ["name", "Reporting User"],
    ["owner", "Ledger Owner"],
    ["reason", "Reason"],
    ["createdAt", "Report Date"],
    ["isUserBlockedD", "Block Reported User"],
    ["isPostBlockedD", "Block Reported Post"],

];
export const eventUserExportData = [
    ["no", "User Id"],
    ["profilePic", "Profile"],
    ["name", "User"],
    ["userName", "User Name"],

];
export const eventTransactionExportData = [
    ["no", "Transaction Id"],
    ["profilePic", "Profile"],
    ["name", "Buyer"],
    ["entries", "Entries"],
    ["fees", "Fees"],
    ["convenience", "Convenience"],
    ["status", "Status"],
    ["createdAt", "CreatedAt"],

];