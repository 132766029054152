import React from "react";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import profile from "../../../asset/image/image 2.png";
import PageComponent from "../../../component/common/Page-Component";
import { requestExportData } from "../../../util/exportData";

const Request = () => {
  const navigate = useNavigate();


  // console.log(club);
  const clubRequest = (id) => {
    navigate(`/app/request/${id}`);
  };


  return (
    <>

      <div className="my-5">
        <PageComponent
          tableHeaders="REQUEST"
          tableTitle="Request List"
          getAPI={CONSTANTS.API.getClubRequest}
          getData={(res) => {
            return res?.map((data) => {
              return {
                ...data,
                no: `R${data?.id.toString().padStart(7, "0")}`,
                image: data?.image || profile,
              };
            });
          }}
          extraResData="clubs"
          viewData
          viewFunction={clubRequest}
          acceptRejectData
          acceptRejectAPI={CONSTANTS.API.acceptOrRejectRequest}
          exportData={requestExportData}
          exportCSV
        />
      </div>
    </>
  );
};

export default Request;
