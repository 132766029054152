import React from 'react'
import PageComponent from '../../../../../component/common/Page-Component'
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CONSTANTS from '../../../../../util/constant/CONSTANTS';
import { clubValetExportData } from '../../../../../util/exportData';

const Valet = () => {
    const { id } = useParams();
    return (
        <div>

            <PageComponent
                tableTitle="Valets List"
                tableHeaders="CLUBS_STAFF"
                getAPI={CONSTANTS.API.getClubValet}
                extraParams={`&ClubId=${id}`}
                extraResData='valets'
                getData={(res) => {
                    //   console.log(res);
                    return res?.map(data => {
                        return {
                            ...data,
                            no: `V${data?.id.toString().padStart(7, "0")}`,
                            clubName: data?.Club?.name,
                            staffs: 'Valet',
                            date: moment.utc(data?.createdAt).local().format('DD MMM, YYYY'),


                        }
                    })
                }}
                exportData={clubValetExportData}
                exportCSV
            />

        </div>
    )
}

export default Valet