import React from 'react'
import PageComponent from '../../../../../component/common/Page-Component'
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CONSTANTS from '../../../../../util/constant/CONSTANTS';
import { clubBouncerExportData } from '../../../../../util/exportData';

const Bouncer = () => {
  const { id } = useParams();
  return (
    <div>

      <PageComponent
        tableTitle="Bouncer List"
        tableHeaders="CLUBS_STAFF"
        getAPI={CONSTANTS.API.getBouncers}
        extraParams={`&ClubId=${id}`}
        extraResData='bouncers'
        getData={(res) => {
          //   console.log(res);
          return res?.map(data => {
            return {
              ...data,
              no: `B${data?.id.toString().padStart(7, "0")}`,
              clubName: data?.Club?.name,
              staffs: 'Bouncer',
              date: moment.utc(data?.createdAt).local().format('DD MMM, YYYY'),


            }
          })
        }}
        exportData={clubBouncerExportData}
        exportCSV
      />

    </div>
  )
}

export default Bouncer