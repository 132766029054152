import React from 'react'
import { Card, Tabs } from 'antd';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import User from './tabs/user';
import Post from './tabs/post';
import VisitLedger from './tabs/visitLedger';

const BlockandReports = () => {

    const menus =
    {
        user: <User />,
        post: <Post />,
        ledger: <VisitLedger />,
    }
    return (
        <>
            <Card className="mt-5">
                <Tabs
                    className="p-0 m-0"
                    defaultActiveKey="1"
                    size="middle"
                    type="card"
                    items={CONSTANTS.TAB_MENU.REPORT.map((ele) => {
                        return {
                            label: `${ele.Label}`,
                            key: `${ele.id}`,
                            children: menus[ele.id],
                        };
                    })}
                />
            </Card>

        </>
    )
}

export default BlockandReports