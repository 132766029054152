import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spin, Tabs } from "antd";

import UserProfile from "./user-profile";
import UserProfileDetails from "./user-profile-detail";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import PreviousBooking from "./tabs/previous-booking";
import CarValet from "./tabs/car-valet";
import Visits from "./tabs/visists";
import AboutInfo from "./tabs/about-info";
import ChatHistory from "./tabs/chat-history";
import useHttp from "../../../hooks/use-http";
import { useParams } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";

const UserDetail = () => {
  const [UserData, setUserData] = useState({});
  const [refresh, setRefresh] = useState(false);
  // , 'valet', 'chat', 'post', 'about'
  const menus =
  {
    booking: <PreviousBooking />,
    valet: <CarValet />,
    chat: <ChatHistory />,
    post: <Visits />,
    about: <AboutInfo {...UserData} />
  }
    ;
  const api = useHttp();
  const { id } = useParams();
  const dataId = id;

  //  console.log(UserData);

  useEffect(() => {
    const USER_DETAIL_API = apiGenerator(CONSTANTS.API.getOneUser, { dataId });
    api.sendRequest(USER_DETAIL_API, (res) => {
      setUserData(res?.data?.user);
    });
  }, [refresh]);
  return (
    <>
      {api.isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      ) : (
        <div className="mt-10">
          <Row gutter={[16, 16]}>
            <Col span={7} xs={24} sm={24} md={24} lg={7} xl={7}>
              <UserProfile {...UserData} setRefresh={setRefresh} />
            </Col>
            <Col span={17} xs={24} sm={24} md={24} lg={17} xl={17}>
              <UserProfileDetails {...UserData} />
            </Col>
          </Row>
          <Card className="mt-10">
            <Tabs
              className="p-0 m-0"
              defaultActiveKey="1"
              size="middle"
              type="card"
              items={CONSTANTS.TAB_MENU.USER.map((ele) => {
                return {
                  label: `${ele.Label}`,
                  key: `${ele.id}`,
                  children: menus[ele.id],
                };
              })}
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default UserDetail;
