import React from 'react'
import { Card, Tabs } from 'antd';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import Clubs from './clubs';
import IncompleClubs from './incompleClubs';


const clubTab = () => {

  const menus =
  {
    clubs: <Clubs />,
    incompleted: <IncompleClubs />,
  }
  return (
    <>
      <Card className="mt-5">
        <Tabs
          className="p-0 m-0"
          defaultActiveKey="1"
          size="middle"
          type="card"
          items={CONSTANTS.TAB_MENU.CLUB_TABS.map((ele) => {
            return {
              label: `${ele.Label}`,
              key: `${ele.id}`,
              children: menus[ele.id],
            };
          })}
        />
      </Card>

    </>
  )
}

export default clubTab