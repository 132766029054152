import { Navigate } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import LogIn from "../page/user/login";
import Registration from "../page/user/Registration";
import AppLayout from "../component/layout/AppLayout";
import Dashboard from "../page/app/dashboard";
import { appRoot } from "./constant/CONSTANTS";
import ForgetPassword from "../page/user/Registration/forgetPass";
import Request from "../page/app/request";
import Bouncers from "../page/app/bouncers";
import Setting from "../page/app/setting";
import Valets from "../page/app/valets";
import UserDetail from "../page/app/users/user-detail";
import RequestAboutInfo from "../page/app/request/request-about-info";
import Clubs from "../page/app/clubs";
import Chat from "../page/app/users/tabs/chat";
import ClubDetails from "../page/app/clubs/club-details";
import EventDetails from "../page/app/clubs/tabs/Evetnt-details";
import FodoCategory from "../page/app/food-category";
import Users from "../page/app/users";
import Manager from "../page/app/manager";
import Events from "../page/app/events";
import BlockandReports from "../page/app/block and report";

export const ROUTES = {
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  USERS_CHAT: "/users/:userId/:id",
  USERS_DETAIL: "/users/:id",
  CLUBS: "/clubs",
  EVENTS: "/events",
  CLUBS_DETAILS: "/clubs/:id",
  CLUBS_EVENT_DETAILS: "/clubs/event/:id",
  REQUEST: "/request",
  CLUB_REQUEST: "/request/:id",
  BOUNCERS: "/bouncers",
  MANAGER: "/managers",
  VALETS: "/valets",
  FEED: "/feed",
  EVENTS: "/events",
  FOOD_CATEGORY: "/food-category",
  SETTING: "/setting",
  REPORT: "/report",


};

const LOGIN_ROUTES = [
  // {
  //   path: "/",
  //   element: <SidePage />,
  //   errorElement: <Error />,
  //   children: [
  //     {
  //       index: true,
  //       element: <LogIn />,
  //     },
  //     {
  //       path: "registration",
  //       element: <Registration />,
  //     },
  //   ],
  // },
  {
    index: true,
    element: <LogIn />,
  },
  {
    path: "registration",
    element: <Registration />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgetPassword />,
    errorElement: <Error />,
  },
];

const ALL_ROUTES = [
  ...LOGIN_ROUTES,

  {
    path: `${appRoot}`,
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={`${appRoot}${ROUTES.DASHBOARD}`} />,
      },
      {
        path: `${appRoot}${ROUTES.DASHBOARD}`,
        element: <Dashboard />,
      },
      {
        path: `${appRoot}${ROUTES.USERS}`,
        element: <Users />,
      },
      {
        path: `${appRoot}${ROUTES.USERS_CHAT}`,
        element: <Chat />,
      },
      {
        path: `${appRoot}${ROUTES.CLUBS}`,
        element: <Clubs />,
      },
      {
        path: `${appRoot}${ROUTES.CLUBS_DETAILS}`,
        element: <ClubDetails />,
      },
      {
        path: `${appRoot}${ROUTES.CLUBS_EVENT_DETAILS}`,
        element: <EventDetails />,
      },
      {
        path: `${appRoot}${ROUTES.USERS_DETAIL}`,
        element: <UserDetail />,
      },
      {
        path: `${appRoot}${ROUTES.EVENTS}`,
        element: <Events />,
      },
      {
        path: `${appRoot}${ROUTES.REQUEST}`,
        element: <Request />,
      },
      {
        path: `${appRoot}${ROUTES.CLUB_REQUEST}`,
        element: <RequestAboutInfo />,
      },
      {
        path: `${appRoot}${ROUTES.MANAGER}`,
        element: <Manager />,
      },
      {
        path: `${appRoot}${ROUTES.BOUNCERS}`,
        element: <Bouncers />,
      },
      {
        path: `${appRoot}${ROUTES.VALETS}`,
        element: <Valets />,
      },

      {
        path: `${appRoot}${ROUTES.FOOD_CATEGORY}`,
        element: <FodoCategory />,
      },
      {
        path: `${appRoot}${ROUTES.EVENTS}`,
        element: (
          <Navigate to={`${appRoot}${ROUTES.EVENTS}${ROUTES.USER_EVENTS}`} />
        ),
      },


      {
        path: `${appRoot}${ROUTES.REPORT}`,
        element: <BlockandReports />,
      },

      {
        path: `${appRoot}${ROUTES.SETTING}`,
        element: <Setting />,
      },
    ],
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/unAuthorize",
    element: <UnAuthorize />,
  },
  {
    path: "*",
    element: <Navigate to="/error" />,
  },
];

export default ALL_ROUTES;
