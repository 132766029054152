import React, { useEffect, useState } from "react";
import PageComponent from "../../../../../component/common/Page-Component";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import useHttp from "../../../../../hooks/use-http";
import { clubMenuExportData } from "../../../../../util/exportData";

const Menu = () => {
  const [filterList, setFilterList] = useState([{ title: 'Select All', value: "" }])
  const { id } = useParams();

  const api = useHttp();
  // const filterList = [
  //   {
  //     value: "Select All",
  //     title: "Select All",
  //   },
  //   {
  //     value: "Pizza",
  //     title: "Pizza",
  //   },
  //   {
  //     value: "Burgers",
  //     title: "Burgers",
  //   },
  //   {
  //     value: "Rice",
  //     title: "Rice",
  //   },
  //   {
  //     value: "Chinese",
  //     title: "Chinese",
  //   },
  // ];
  useEffect(() => {
    const GET_FOOD_CATEGORY_API = { ...CONSTANTS.API.getFoodCategory }
    api.sendRequest(GET_FOOD_CATEGORY_API, (res) => {
      setFilterList(prev => {
        return [...prev, ...res?.data?.foodCategories?.map(data => {
          return {
            title: data?.category,
            value: `FoodCategoryId=${data?.id}`
          }
        })]
      })
      // console.log(res?.data?.foodCategories);
    })

  }, [])
  return (
    <>
      <PageComponent
        tableTitle="Menu List"
        tableHeaders="CLUB_FLOATING_ICON_MENU"
        getAPI={CONSTANTS.API.getClubFood}
        extraParams={`&ClubId=${id}`}
        extraResData="foodItems"
        getData={(res) => {
          // console.log(res);
          return res?.map(data => {
            return {
              ...data,
              no: `F${data?.id.toString().padStart(7, "0")}`,
              price: `₹${data?.price || 0}`,
              discount: `${data?.discount || 0}%`,


            }
          })
        }}

        filterparmas
        filterList={filterList}
        exportData={clubMenuExportData}
        exportCSV
      />
    </>
  );
};

export default Menu;
