import React from "react";
import PageComponent from "../../../../component/common/Page-Component";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import moment from "moment";
import { userBookingExportData } from "../../../../util/exportData";

const PreviousBooking = () => {
  const { id } = useParams()
  // console.log(id);
  // const BOOKING_API = { ...CONSTANTS.API.getUserBooking }
  // BOOKING_API.endpoint = `${BOOKING_API.endpoint}?UserId=${id}`
  // console.log(BOOKING_API);
  return (
    <div className="mt-5 mx-3">
      <PageComponent
        tableHeaders="USERS_BOOKING"
        tableTitle="Booking List"
        getAPI={CONSTANTS.API.getUserBooking}
        extraParams={`&UserId=${id}`}
        extraResData="bookings"
        getData={(res) => {
          // console.log(res);
          return res?.map(data => {
            return {
              ...data,
              no: `B${data?.id.toString().padStart(7, "0")}`,
              image: data?.Event?.Club?.image,
              club: data?.Event?.Club?.name,
              eventName: data?.Event?.name,
              date: `${moment(data?.Event?.startDate).format(
                "DD MMM"
              )}, ${moment
                .utc(data?.Event?.startDate)
                .local()
                .format("hh:mm A")}`,
              entries: data?.participants,
              total: `₹${data?.entries * (data?.fees + data?.convenience)}`,
            };
          });
        }}
        exportData={userBookingExportData}
      />
    </div>
  );
};

export default PreviousBooking;
