import { Card, Tabs } from 'antd';
import React from 'react'
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import Menu from './floating-icon tabs/Menu';
import Banners from './floating-icon tabs/Banners';
import CarValet from './floating-icon tabs/Car-valet';

const FloatingIcon = () => {

   const sub_menu = [
     { menu: <Menu /> },
     { banner: <Banners /> },
     { valet: <CarValet /> },
   ];

  return (
    <>
      <Card className="mt-5">
        <Tabs
          className="p-0 m-0"
          defaultActiveKey="1"
          size="middle"
          type="card"
          items={CONSTANTS.TAB_MENU.CLUB_FLOATING_ICON.map((ele) => {
            return {
              label: `${ele.Label}`,
              key: `${ele.id}`,
              children: sub_menu.map((data) => {
                if (Object.keys(data)[0] === ele.id) {
                  return data[ele.id];
                }
              }),
            };
          })}
        />
      </Card>
    </>
  );
}

export default FloatingIcon