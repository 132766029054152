import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row, Tabs } from "antd";
import imageSrc from "../../../../asset/image/image 2.png";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import { useParams } from "react-router-dom";
import useHttp from "../../../../hooks/use-http";
import moment from "moment";
import About from "../../events/tabs/about";
import UserList from "../../events/tabs/user";
import Transaction from "../../events/tabs/transaction";

const EventDetails = ({
  image,
  creatorName,
  details,
  artist,
  address,
  fee,
  booking,
  attended,
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [info, SetInfo] = useState({});
  const [refresh, setRefresh] = useState(false)
  const { id } = useParams()
  const api = useHttp()

  const menus = [
    { about: <About info={info} setRefresh={setRefresh} /> },
    { users: <UserList /> },
    { transaction: <Transaction /> },
  ];


  // const notificationHandler = (value) => {
  //   console.log(value);
  //   const NOTIFICATION_API = apiGenerator(CONSTANTS.API.sentEventNotification, {}, `?EventId=${id}`)
  //   // console.log(NOTIFICATION_API);
  //   api.sendRequest(NOTIFICATION_API, () => setIsModalOpen((prev) => !prev), value, 'Sent Notification Sucessfully!!!')
  // };

  useEffect(() => {
    const EVENT_API = apiGenerator(CONSTANTS.API.getOneEvent, {
      dataId: id
    })
    api.sendRequest(EVENT_API, (res) => {
      SetInfo(res?.data?.event)
    })

  }, [refresh])
  return (
    <>
      <div className="select-text">
        <div className="my-5">
          <Row gutter={[16, 16]}>
            {/* Event Profile */}
            <Col span={7} xs={24} sm={24} md={24} lg={7} xl={7}>
              <Row gutter={[16, 16]}>
                <Col span={14} xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card className="py-3">
                    <div>
                      <Row className="mt-5">
                        <Col span={7}>
                          <Image
                            width={80}
                            height={80}
                            className="rounded-full"
                            preview
                            src={info?.User?.profilePic || info?.Club?.image || image}
                            alt="Images is Not Availabel"
                          />
                        </Col>
                        <Col span={17}>
                          <p className="font-bold mt-7 lg:text-xl ml-5">
                            {info?.User?.name || info?.Club?.name}
                          </p>
                        </Col>
                      </Row>

                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            {/* Event Profile Details */}
            <Col span={17} xs={24} sm={24} md={24} lg={17} xl={17}>
              <Row gutter={[16, 16]}>
                <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Card size="small" className="py-2 px-4">
                    <div>
                      <p className="font-semibold text-lg text-slate-400">
                        Total Revenue
                      </p>
                      <p className=" text-xl font-medium">₹{+info?.clubAmount + +info?.bashAmount}</p>
                    </div>
                  </Card>
                </Col>
                <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Card size="small" className="py-2 px-4">
                    <div>
                      <p className="font-semibold  text-lg text-slate-400">
                        Club Own
                      </p>
                      <p className=" text-xl font-medium">₹{info?.clubAmount || 0}</p>
                    </div>
                  </Card>
                </Col>
                <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Card size="small" className="py-2 px-4">
                    <div>
                      <p className="font-semibold text-lg text-slate-400">
                        Bash Own
                      </p>
                      <p className=" text-xl font-medium">₹{info?.bashAmount || 0}</p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* Club About Info */}
        <div>
          <Card>
            <Tabs
              className="p-0 m-0"
              defaultActiveKey="1"
              size="middle"
              type="card"
              items={CONSTANTS.TAB_MENU.EVENT.map((ele) => {
                return {
                  label: `${ele.Label}`,
                  key: `${ele.id}`,
                  children: menus.map((data) => {
                    if (Object.keys(data)[0] === ele.id) {
                      return data[ele.id];
                    }
                  }),
                };
              })}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default EventDetails;

EventDetails.defaultProps = {
  image: imageSrc,

  name: "No Data",

  creatorName: "No Data",
  details: "No Data",
  artist: "No Data",
  startDate: "No Data",
  endDate: "No Data",
  address: "No Data",
  fee: "0",
  booking: "0",
  attended: "0",
};
