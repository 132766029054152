export const apiGenerator = (apiObject, exchangePair = {}, join = null) => {
  const apiObj = { ...apiObject };
  if (Object.keys(exchangePair).length) {
    Object.keys(exchangePair).forEach((el) => {
      apiObj.endpoint = apiObj.endpoint.replace(`:${el}`, exchangePair[el]);
    });
  }

  if (join) {
    apiObj.endpoint = `${apiObj.endpoint}${join}`;
  }
  return apiObj;
};
export const RSVP_STATUS = {
  '-1': "Pending Event",
  '0': "You declined the invitation",
  '1': "You accepted the invitation",

}

export const renderDescriptionContent = (description, showMore) => {
  if (showMore) {
    return description;
  }


  if (description?.split(" ")?.length > 7) {
    return description?.split(" ")?.slice(0, 7)?.join(" ")?.concat("...");

  }
  else {
    return description;
  }
};
