import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import PageComponent from '../../../../component/common/Page-Component';
import moment from 'moment';
import { eventTransactionExportData } from '../../../../util/exportData';
import { Modal } from 'antd';

const Transaction = () => {
    const [ticketList, setTicketList] = useState([])
    const { id } = useParams()

    const EVENT_TRANSACTION_API = { ...CONSTANTS.API.getEventTransaction }
    EVENT_TRANSACTION_API.endpoint = `${EVENT_TRANSACTION_API.endpoint}/${id}`
    return (
        <>
            <div>
                <PageComponent
                    tableHeaders="EVENTS_TRANSACTION"
                    tableTitle="Transactions"
                    getAPI={EVENT_TRANSACTION_API}
                    extraResData="bookings"
                    getData={(res) => {
                        return res?.map((data) => {

                            return {
                                ...data,
                                no: `T${data?.id.toString().padStart(7, "0")}`,
                                name: data?.User?.name,
                                profilePic: data?.User?.profilePic,
                                convenience: `₹${data?.convenience}`,
                                fees: `₹${data?.fees}`,
                                createdAt: data?.createdAt ? moment.utc(data?.createdAt).local().format('DD MMM, YYYY') : '',
                                view: {
                                    id: data?.id,
                                    disable: !(data?.Tickets?.length > 0),
                                    onClick: () => {
                                        setTicketList(data?.Tickets)
                                    }
                                }
                            };
                        });
                    }}
                    exportData={eventTransactionExportData}
                    exportCSV
                />
            </div>
            <Modal
                // title="Vertically centered modal dialog"
                centered
                open={ticketList.length > 0}
                onCancel={() => setTicketList([])}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
            >


                <section>
                    <h1>PayId List</h1>

                    {/* Data */}
                    {ticketList?.map((ele) => {
                        return (
                            <div key={ele?.id}>

                                <p className="text-lg m-0">
                                    PayId : {ele?.payId}
                                </p>

                            </div>
                        );
                    })}
                </section>
            </Modal>
        </>
    )
}

export default Transaction